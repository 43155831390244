import {
  ButtonsRendererComponent, ButtonsRendererConfig,
  ColumnDefinition,
  ColumnRendererComponent,
  DateRendererComponent,
  DateRendererConfig,
  TranslationRendererComponent,
} from '@gea/digital-ui-lib';

export const temporaryMembershipsColumns: ColumnDefinition[] = ([
  {
    displayName: 'X.LABEL.ORGANISATION',
    key: 'organizationName',
    width: 350,
    minWidth: 300,
  },
  {
    displayName: 'X.LABEL.ROLE',
    key: 'roleName',
    renderer: {
      component: TranslationRendererComponent as ColumnRendererComponent<unknown>,
      config: {
        prefix: 'X.ROLE.',
      },
    }
  },
  {
    displayName: 'X.LABEL.LANGUAGE',
    key: 'languageLabel',
    renderer: {
      component: TranslationRendererComponent as ColumnRendererComponent<unknown>,
      config: {
        prefix: 'X.LANGUAGE.',
      },
    }
  },
  {
    displayName: 'TEMP_MEMBERSHIP.START_APP',
    key: 'appKey',
    renderer: {
      component: TranslationRendererComponent as ColumnRendererComponent<unknown>,
      config: {
        prefix: '',
      },
    }
  },
  {
    displayName: 'X.LABEL.CREATED_DATE',
    key: 'createdAt',
    renderer: {
      component: DateRendererComponent as ColumnRendererComponent<unknown>,
      config: {
        format: 'dd.MM.yyyy HH:mm:ss',
      } as DateRendererConfig,
    },
  },
  {
    displayName: '',
    key: 'buttons',
    width: 150,
    minWidth: 100,
    sortable: false,
    renderer: {
      component: ButtonsRendererComponent as ColumnRendererComponent<unknown>,
      config: {
        buttons: [
          {
            text: 'TEMP_MEMBERSHIP.BUTTON.RETRIEVE',
            action: 'retrieve',
            postIcon: true,
          } ,
        ],
      } as ButtonsRendererConfig,
    }
  },
]);