import { Component } from '@angular/core';

import { ColumnRendererComponent } from '@gea/digital-ui-lib';

import { TranslationDeployment } from '../translation-files-api.service';

@Component({
  selector: 'gea-id-workspace-datetime-renderer',
  templateUrl: './datetime-renderer.component.html',
})
export class DatetimeRendererComponent implements ColumnRendererComponent<TranslationDeployment> {
  rowData?: TranslationDeployment;
}
