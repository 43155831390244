<geaui-page-content-layout>
  <ng-container page-content>
    <form [formGroup]="form">
      <section class="geaui-grid geaui-sidebar-grid">
        <div
          class="gea-font-heading-20 flex-down geaui-row-desktop-md-start-1 geaui-col-desktop-md-start-1 geaui-col-desktop-xl-start-7 geaui-col-desktop-md-7 header-content">
          {{ 'X.LABEL.PERSONAL_DATA' | translate }}
        </div>
        <geaui-tooltip-v2
          class="geaui-tooltip geaui-row-desktop-md-start-2 geaui-row-desktop-md-2 geaui-col-desktop-md-start-9 geaui-col-desktop-md-3">
          {{ 'X.INFO.NO_ACCESS.NO_EDIT_USER' | translate }}</geaui-tooltip-v2
        >
        <geaui-autocomplete-select-v2
          class="geaui-row-desktop-md-start-2 geaui-col-desktop-xl-start-7 geaui-col-desktop-md-start-1 geaui-col-desktop-md-2"
          [id]="FORM_CONTROL_TITLE"
          [formControlName]="FORM_CONTROL_TITLE"
          [formControl]="form.controls[FORM_CONTROL_TITLE]"
          [disabled]="isDisabled"
          [label]="'USERS.USER_DETAIL.FORM.PERSONAL_INFO.TITLE.LABEL' | translate"
          [loading]="loading"
          [options]="titleOptions">
        </geaui-autocomplete-select-v2>
        <geaui-select-v2
          class="geaui-col-desktop-xl-start-11 geaui-col-desktop-md-start-5 geaui-col-desktop-md-4"
          [label]="'X.LABEL.CUSTOMER_USER_TYPE' | translate"
          [group]="form"
          [options]="customerTypeOptions"
          [loading]="loading"
          [required]="true"
          [disabled]="!!form.get(FORM_CONTROL_CUSTOMER_TYPE)?.disabled"
          formName="customerUserType"></geaui-select-v2>
        <geaui-input-v2
          class="geaui-col-desktop-xl-start-7 geaui-col-desktop-md-start-1 geaui-col-desktop-md-4"
          [id]="FORM_CONTROL_FIRSTNAME"
          [floatLabelWhileDisabled]="isDisabled"
          [group]="form"
          [disabled]="isDisabled"
          [label]="'X.LABEL.FIRST_NAME' | translate"
          [loading]="loading"
          [required]="true"></geaui-input-v2>
        <geaui-input-v2
          class="geaui-col-desktop-xl-start-11 geaui-col-desktop-md-start-5 geaui-col-desktop-md-4"
          [id]="FORM_CONTROL_LASTNAME"
          [floatLabelWhileDisabled]="isDisabled"
          [group]="form"
          [disabled]="isDisabled"
          [label]="'X.LABEL.LAST_NAME' | translate"
          [loading]="loading"
          [required]="true"></geaui-input-v2>
        <geaui-input-v2
          class="geaui-col-desktop-xl-start-7 geaui-col-desktop-md-start-1 geaui-col-desktop-md-4"
          [id]="FORM_CONTROL_EMAIL"
          [floatLabelWhileDisabled]="isDisabled"
          [group]="form"
          [disabled]="isDisabled"
          [label]="'X.LABEL.EMAIL' | translate"
          [loading]="loading"
          [required]="true">
        </geaui-input-v2>
        <geaui-input-v2
          class="geaui-col-desktop-xl-start-11 geaui-col-desktop-md-start-5 geaui-col-desktop-md-4"
          [id]="FORM_CONTROL_PHONE"
          [floatLabelWhileDisabled]="isDisabled"
          [group]="form"
          [disabled]="isDisabled"
          [label]="'X.LABEL.PHONE' | translate"
          [loading]="loading"
          [required]="false">
        </geaui-input-v2>

        <div class="geaui-col-desktop-xl-start-7 geaui-col-desktop-md-start-1 geaui-col-desktop-md-8">
          <div class="divider"></div>
        </div>

        <div class="gea-font-heading-20 geaui-col-desktop-xl-start-7 geaui-col-desktop-md-12 mt-25">
          {{ 'USERS.USER_DETAIL.FORM.SETTINGS.LABEL' | translate }}
        </div>

        <geaui-autocomplete-select-v2
          class="geaui-col-desktop-xl-start-7 geaui-col-desktop-md-start-1 geaui-col-desktop-md-4"
          [id]="FORM_CONTROL_LANGUAGE"
          [formControlName]="FORM_CONTROL_LANGUAGE"
          [formControl]="form.controls[FORM_CONTROL_LANGUAGE]"
          [disabled]="isDisabled"
          [label]="'X.LABEL.LANGUAGE' | translate"
          [loading]="loading"
          [options]="languageOptions"
          [required]="true"
          (selectChanged)="onLocaleChanged()">
        </geaui-autocomplete-select-v2>
        <geaui-autocomplete-select-v2
          class="geaui-col-desktop-xl-start-11 geaui-col-desktop-md-start-5 geaui-col-desktop-md-4"
          id="country"
          [id]="FORM_CONTROL_COUNTRY"
          [formControlName]="FORM_CONTROL_COUNTRY"
          [formControl]="form.controls[FORM_CONTROL_COUNTRY]"
          [disabled]="isDisabled"
          [label]="'X.LABEL.COUNTRY' | translate"
          [loading]="loading"
          [options]="countryOptions"></geaui-autocomplete-select-v2>
        <div class="geaui-col-desktop-xl-start-16 geaui-col-desktop-md-start-10 geaui-col-desktop-md-2">
          <geaui-toggle-v2
            [isLabelLeft]="true"
            [id]="FORM_CONTROL_OPT_MFA"
            [form]="form"
            [flex]="true"
            [disabled]="isDisabled"
            [loading]="loading"
            (toggled)="(profileData?.optInMFA)"
            label="USERS.USER_DETAIL.FORM.SETTINGS.OPT_IN_MFA.LABEL"
            labelClass="gea-font-body-18">
          </geaui-toggle-v2>

          @if (profileData?.id && profileData?.enforcedMFA) {
            <div class="geaui-fieldset-hint">
              <span>{{ 'USERS.USER_DETAIL.FORM.SETTINGS.ENFORCED_MFA_HINT.LABEL' | translate }}</span>
            </div>
          }
        </div>
        <geaui-autocomplete-select-v2
          class="geaui-col-desktop-xl-start-7 geaui-col-desktop-md-start-1 geaui-col-desktop-md-4"
          [id]="FORM_CONTROL_TIMEZONE"
          [formControlName]="FORM_CONTROL_TIMEZONE"
          [formControl]="form.controls[FORM_CONTROL_TIMEZONE]"
          [label]="'USERS.USER_DETAIL.FORM.SETTINGS.TIME_ZONE.LABEL' | translate"
          [disabled]="isDisabled"
          [loading]="loading"
          [options]="timeZoneOptions">
        </geaui-autocomplete-select-v2>
        <geaui-input-v2
          class="geaui-col-desktop-xl-start-11 geaui-col-desktop-md-start-5 geaui-col-desktop-md-4"
          [id]="FORM_CONTROL_CONTACT_ID"
          [group]="form"
          [disabled]="(hasNoUpdatePermission | async)!"
          [floatLabelWhileDisabled]="(hasNoUpdatePermission | async)!"
          [label]="'USERS.USER_DETAIL.FORM.SETTINGS.CONTACT_ID.LABEL' | translate"
          [loading]="loading"
          [required]="false">
        </geaui-input-v2>
        <div class="geaui-col-desktop-xl-start-16 geaui-col-desktop-md-start-10 geaui-col-desktop-md-2">
          <geaui-toggle-v2
            [isLabelLeft]="true"
            [disabled]="isDisabled"
            [id]="FORM_CONTROL_ENFORCE_MFA"
            [form]="form"
            [flex]="true"
            [loading]="loading"
            (toggled)="(profileData?.enforcedMFA)"
            label="USERS.USER_DETAIL.FORM.SETTINGS.ENFORCED_MFA.LABEL"
            labelClass="gea-font-body-18">
          </geaui-toggle-v2>
        </div>

        @if (deleteButtonVisibility | async) {
          <span class="geaui-col-desktop-xl-start-7 geaui-col-desktop-md-start-1 geaui-col-desktop-md-4">
            <geaui-link-v2 [preIcon]="'16px_trash-can'" [type]="'cancel-red'" (click)="showRemoveAccountDialog()">
              {{ 'X.BUTTON.DELETE' | translate }}
            </geaui-link-v2>
          </span>
        }
      </section>
    </form>
  </ng-container>

  <ng-container page-footer>
    <geaui-page-footer [withSidebar]="true">
      <ng-container footer-button>
        <geaui-button-v2
          [sending]="isSending"
          [disabled]="isSaveButtonDisabledByForm || (hasNoUpdatePermission | async)! || loading"
          (click)="saveProfileUser()">
          {{ 'X.BUTTON.SAVE' | translate }}
        </geaui-button-v2>
      </ng-container>
    </geaui-page-footer>
  </ng-container>
</geaui-page-content-layout>
