<geaui-page-content-layout [useDefaultPadding]="false">
  <ng-container page-header>
    <geaui-page-header class="home-page-header" [withSidebar]="true" [hasBackArrow]="false" pageTitle="GENERAL.DASHBOARD">
      <div class="page-header-content">
        <div class="edit-dashboard-container">
          <geaui-link-v2 (click)="editDashboard()" type="secondary" preIcon="16px_pen"></geaui-link-v2>
        </div>
      </div>
    </geaui-page-header>
  </ng-container>

  <ng-container page-content>
    <div class="dashboard-content">
      <geaui-grid
        class="grid"
        [storedWidgetSettings]="storedWidgetSettings"
        [widgetDefinitions]="widgetDefinitions"
        [hideEditButton]="true"
        [showEditRow]="false"
        [editModeActive]="editModeActive"
        (editModeUpdated)="editModeActive = $event"
        (editWidgetClicked)="editModeActive = !editModeActive">
      </geaui-grid>
    </div>
  </ng-container>
</geaui-page-content-layout>
