<form [formGroup]="form">
  <div class="flex">
    <geaui-autocomplete-select-v2
      class="geaui-row-desktop-md-start-1 geaui-col-desktop-md-start-1 geaui-col-desktop-md-12"
      [loading]="loading"
      [options]="featureFlagSharedCollection"
      [formControl]="form.controls.featureFlag"
      label="FEATURE_FLAG.MODAL.FORM.FEATURE_FLAG">
    </geaui-autocomplete-select-v2>
    <geaui-autocomplete-select-v2
      class="geaui-row-desktop-md-start-2 geaui-col-desktop-md-start-1 geaui-col-desktop-md-12"
      [loading]="loading"
      [options]="filteredAssignmentType"
      [formControl]="form.controls.assignmentType"
      label="FEATURE_FLAG.MODAL.FORM.ASSIGNMENT_TYPE">
    </geaui-autocomplete-select-v2>
    @switch (form.value.assignmentType) {
      @case (AssignmentTypeModel.COUNTRY) {
        <geaui-autocomplete-select-v2
          [loading]="loading"
          [options]="countries"
          [formControl]="form.controls.assignedTo"
          label="FEATURE_FLAG.MODAL.FORM.ASSIGNED_TO.COUNTRY">
        </geaui-autocomplete-select-v2>
      }
      @case (AssignmentTypeModel.USER) {
        <geaui-autocomplete-select-v2
          [loading]="loading"
          [emptyMessageKey]="emptyMessageKey"
          [preFilter]="fetchUser"
          [options]="usersOptions"
          [formControl]="form.controls.assignedTo"
          label="FEATURE_FLAG.MODAL.FORM.ASSIGNED_TO.USER">
        </geaui-autocomplete-select-v2>
      }
      @case (AssignmentTypeModel.ADMIN) {
        <geaui-autocomplete-select-v2
          [loading]="loading"
          [emptyMessageKey]="emptyMessageKey"
          [preFilter]="fetchAdmin"
          [options]="adminOptions"
          [formControl]="form.controls.assignedTo"
          label="FEATURE_FLAG.MODAL.FORM.ASSIGNED_TO.ADMIN">
        </geaui-autocomplete-select-v2>
      }
      @case (AssignmentTypeModel.ORGANIZATION) {
        <geaui-autocomplete-select-v2
          [loading]="loading"
          [options]="organizationsOptions"
          [emptyMessageKey]="emptyMessageKey"
          [preFilter]="fetchOrganization"
          [formControl]="form.controls.assignedTo"
          label="FEATURE_FLAG.MODAL.FORM.ASSIGNED_TO.ORGANIZATION">
        </geaui-autocomplete-select-v2>
      }
    }

    @if (isEditing) {
      <geaui-link-v2 class="delete-button" [loading]="loading" (click)="delete()" preIcon="16px_trash-can" type="cancel-red">
        {{ 'X.BUTTON.DELETE' | translate }}
      </geaui-link-v2>
    }
  </div>
</form>
