import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ApiErrorResponse,
  ColumnDefinition,
  ErrorHandlerV2Service,
  FilterTableSettings,
  PermissionKey,
  PermissionsState,
  TableServiceV2,
} from '@gea/digital-ui-lib';
import { first, Observable, startWith, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NEW_ORGA_ID, orgaRoutes } from '../models/organizations.routing';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { OrgaData, OrganizationService } from '@gea-id/shared';
import { OrganizationsColumnDefinitionsService } from '../services/organizations-column-definitions.service';

@Component({
  selector: 'gea-id-organizations-list-page',
  styleUrl: './organizations-list-page.component.scss',
  templateUrl: './organizations-list-page.component.html',
})
export class OrganizationsListPageComponent implements OnInit, OnDestroy {
  public TABLE_ID = 'admin-organization-table';
  public data: OrgaData[] = [];
  public columnDefinitions$: Observable<ColumnDefinition[]> = this.organizationsColumnDefinitionsService.organizationColumnDefinitions$;
  public totalRecords = 0;

  public loading = true;
  public subs: Subscription[] = [];

  constructor(
    private organizationService: OrganizationService,
    private store: Store,
    private tableService: TableServiceV2,
    private errorHandler: ErrorHandlerV2Service,
    private router: Router,
    private organizationsColumnDefinitionsService: OrganizationsColumnDefinitionsService
  ) {}

  ngOnInit(): void {
    this.subs.push(this.tableService.getFilterTableSettings(this.TABLE_ID).subscribe((filter) => this.fetchData(filter)));
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  get hasNoCreatePermission(): Observable<boolean> {
    return this.store.select(PermissionsState.userPermissions).pipe(
      startWith([] as PermissionKey[]),
      map((permissions) => {
        return !permissions.includes(PermissionKey.CREATE_ORGANIZATION);
      })
    );
  }

  fetchData(filter: FilterTableSettings) {
    this.loading = true;

    this.organizationService
      .getOrganizationsV2({ ...filter })
      .pipe(first())
      .subscribe({
        next: (data) => {
          this.data = data.pageEntries;
          this.totalRecords = data.entryCount;
          this.loading = false;
        },
        error: (error: ApiErrorResponse) => {
          this.errorHandler.handleError(error);
          this.loading = false;
        },
      });
  }

  public edit(rowData: OrgaData) {
    void this.router.navigate(['organization', rowData.orgaId, orgaRoutes.BASIC_INFO]);
  }

  public add() {
    void this.router.navigate(['organization', NEW_ORGA_ID, orgaRoutes.BASIC_INFO]);
  }
}
