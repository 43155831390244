import { Injectable } from '@angular/core';
import {
  appendVisibilitySettings,
  ChipRendererComponent,
  ChipRendererConfig,
  ColumnDefinition,
  ColumnRendererComponent,
  CustomerUserTypeDto,
  DateRendererComponent,
  DateRendererConfig,
  RendererUtilService,
  SelectFilterComponent,
  TextFilterComponent,
  TranslationRendererComponent,
} from '@gea/digital-ui-lib';
import { UserListItemState } from '@gea-id/shared';

@Injectable({
  providedIn: 'root',
})
export class UsersListColumnDefinitionsService {
  usersColumnDefinitions: ColumnDefinition[] = appendVisibilitySettings([
    this.createTextColumn('X.LABEL.FIRST_NAME', 'firstName', true),
    this.createTextColumn('X.LABEL.LAST_NAME', 'lastName', true),
    this.createTextColumn('X.LABEL.EMAIL', 'email', true, 300),
    {
      ...this.createTextColumn('X.LABEL.CUSTOMER_USER_TYPE', 'customerUserType', true),
      renderer: {
        component: TranslationRendererComponent as ColumnRendererComponent<unknown>,
        config: {
          prefix: 'X.CUSTOMER_USER_TYPE.',
        },
      },
      filter: {
        component: SelectFilterComponent,
        config: {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
          options: this.rendererUtilService.getAllEnumValuesAsSelectFilterOptions(CustomerUserTypeDto, 'X.CUSTOMER_USER_TYPE.'),
        },
      },
    },
    this.createTextColumn('X.LABEL.MEMBERSHIPS', 'numberOfUserMemberships'),
    this.createTextColumn('USERS.LIST.INVITATIONS', 'numberOfInvitations'),
    {
      displayName: 'USERS.LIST.STATUS.HEADER',
      key: 'userListItemState',
      sortable: false,
      filter: {
        component: SelectFilterComponent,
        config: {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
          options: this.rendererUtilService.getAllEnumValuesAsSelectFilterOptions(UserListItemState, 'USERS.LIST.STATUS.'),
        },
      },
      renderer: {
        component: ChipRendererComponent as ColumnRendererComponent<unknown>,
        config: {
          mapping: {
            USER_ACTIVE: {
              severity: 'success',
              text: 'USERS.LIST.STATUS.ACTIVE',
            },
            USER_INACTIVE: {
              severity: 'default',
              text: 'USERS.LIST.STATUS.INACTIVE',
            },
            INVITATION_PENDING: {
              severity: 'information',
              text: 'USERS.LIST.STATUS.PENDING',
            },
            INVITATION_EXPIRED: {
              severity: 'error',
              text: 'USERS.LIST.STATUS.EXPIRED',
            },
          },
        } as ChipRendererConfig,
      },
    },
    this.createTextColumn('USERS.LIST.CONTACT_ID', 'contactId'),
    this.createDateTimeColumn('X.LABEL.CREATED_DATE', 'createdAt'),
    this.createDateTimeColumn('X.LABEL.LAST_MODIFIED_DATE', 'lastModifiedDate'),
    this.createDateTimeColumn('USERS.LIST.REGISTRATION_DATE', 'registrationDate'),
    this.createDateTimeColumn('USERS.LIST.LAST_SIGNIN_DATE', 'lastSignInDate'),
    this.createDateTimeColumn('USERS.LIST.MARKED_FOR_DELETION_DATE', 'markedForDeletionDate'),
  ]);

  constructor(private rendererUtilService: RendererUtilService) {}

  createTextColumn(displayName: string, key: string, filterable = false, columnWidth = 150): ColumnDefinition {
    return {
      displayName,
      key,
      width: columnWidth,
      filter: filterable
        ? {
            component: TextFilterComponent,
          }
        : undefined,
      visible: true,
    };
  }

  createDateTimeColumn(displayName: string, key: string): ColumnDefinition {
    return {
      displayName,
      key,
      renderer: {
        component: DateRendererComponent as ColumnRendererComponent<unknown>,
        config: {
          format: 'dd.MM.yyyy HH:mm:ss',
        } as DateRendererConfig,
      },
      visible: true,
    };
  }
}
