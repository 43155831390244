import { AfterViewInit, Component, DestroyRef, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import {
  ApiErrorResponse,
  ComplexDialogV2Service,
  Customer,
  DialogV2Service,
  ErrorHandlerV2Service,
  OrganizationType,
  PermissionKey,
  PermissionsState,
  SelectOption,
  SnackbarService,
} from '@gea/digital-ui-lib';
import { filter, Observable, startWith, first, forkJoin } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { OrganizationDetailService } from '../services/organization-detail.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NEW_ORGA_ID } from '../../models/organizations.routing';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { CustomerSelectionComponent } from './customer-link';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationDetail, OrganizationDetailResponse, OrganizationService } from '@gea-id/shared';
import { SharepointSiteApiService } from '../services/sharepoint-site-api.service';

@Component({
  selector: 'gea-id-organization-info',
  templateUrl: './organization-info.component.html',
  styleUrl: './organization-info.component.scss',
})
export class OrganizationInfoComponent implements OnInit, AfterViewInit {
  @Input() private orgaId = '';
  protected countries: Observable<SelectOption<string>[]> = this.organizationDetailService.countriesOptions$;
  protected loading = true;
  protected hasNoUpdatePermission = true;

  form = this.fb.group({
    orgaName: ['', Validators.required],
    type: [
      { value: '' as OrganizationType, disabled: true },
      [
        Validators.required,
        (control: AbstractControl) => (control.value === OrganizationType.UNKNOWN ? { required: true } : null),
      ],
    ],
    customerNumber: [''],
    shippingAddress: this.fb.group({
      street: ['', [Validators.required, Validators.maxLength(100)]],
      city: ['', [Validators.required, Validators.maxLength(40)]],
      country: ['', [Validators.required]],
      zipCode: ['', [Validators.required, Validators.maxLength(20)]],
    }),
    sharepointSites: this.fb.group({
      customerPortalIds: [''],
      projectSiteIds: [''],
    }),
    billingAddressSame: [true],
    billingAddress: this.fb.nonNullable.group({
      street: ['', Validators.maxLength(100)],
      city: ['', Validators.maxLength(40)],
      country: [''],
      zipCode: ['', Validators.maxLength(20)],
    }),
  });

  protected get isInvoiceDisabled() {
    return !!this.form.controls.billingAddressSame.value;
  }

  protected get hasDeletePermission(): Observable<boolean> {
    return this.store
      .select(PermissionsState.userPermissions)
      .pipe(map((permissions) => permissions.includes(PermissionKey.DELETE_ORGANIZATION)));
  }

  initHasNoUpdatePermission(): void {
    this.store
      .select(PermissionsState.userPermissions)
      .pipe(
        startWith([] as PermissionKey[]),
        map((permissions) => {
          return !permissions.includes(PermissionKey.UPDATE_ORGANIZATION);
        })
      )
      .subscribe((hasNoUpdatePermission) => {
        this.hasNoUpdatePermission = hasNoUpdatePermission;
      });
  }

  protected get orgaTypes(): Observable<SelectOption<OrganizationType>[]> {
    return this.organizationDetailService.orgaTypes$.pipe(
      map((orgaTypes) =>
        orgaTypes.map((o) => ({
          value: o.type,
          nameKey: `X.ORGA_TYPE.${o.type}`,
        }))
      )
    );
  }

  protected get isNewOrga(): boolean {
    return this.orgaId === NEW_ORGA_ID;
  }

  constructor(
    protected store: Store,
    private fb: FormBuilder,
    private organizationService: OrganizationService,
    private organizationDetailService: OrganizationDetailService,
    private sharepointSiteApiService: SharepointSiteApiService,
    private errorHandler: ErrorHandlerV2Service,
    private _destroyRef: DestroyRef,
    private router: Router,
    private confirmService: DialogV2Service,
    private complexDialogService: ComplexDialogV2Service,
    private snackbar: SnackbarService,
    private confirmDialog: DialogV2Service,
    private translate: TranslateService
  ) {}

  ngAfterViewInit(): void {
    if (this.isNewOrga) {
      this.openCustomerSelection();
      this.form.controls.type.enable();
    }
  }

  ngOnInit() {
    this.initHasNoUpdatePermission();

    this.form.controls.billingAddressSame.valueChanges
      .pipe(startWith(this.form.value.billingAddressSame), takeUntilDestroyed(this._destroyRef))
      .subscribe((value) => {
        if (value) {
          this.form.controls.billingAddress.disable();
        } else {
          this.form.controls.billingAddress.enable();
        }
      });

    this.loading = true;

    if (this.isNewOrga) {
      this.loading = false;
      return;
    }

    // init with real OrgaId
    this.organizationDetailService.initialOrgaData$
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        filter((orga): orga is OrganizationDetailResponse => !!orga && orga.id === this.orgaId)
      )
      .subscribe((orga: OrganizationDetailResponse) => {
        this.fillForm(orga);
        this.loading = false;
      });

    this.sharepointSiteApiService.getSite(this.orgaId).subscribe((sites) => {
      this.form.controls.sharepointSites.patchValue({
        customerPortalIds: sites.customerPortalIds.join(','),
        projectSiteIds: sites.projectSitesId.join(','),
      });
    });
  }

  protected save() {
    this.form.updateValueAndValidity();
    if (this.form.invalid) {
      throw new Error('Form is invalid');
    }

    this.organizationDetailService.update({ orgaType: this.form?.value?.type });

    const sentSharepointIds$ = this.sharepointSiteApiService
      .setSites(
        this.orgaId,
        this.form.value.sharepointSites?.customerPortalIds
          ?.split(',')
          .map((id) => id.trim())
          .filter((id) => !!id) ?? [],
        this.form.value.sharepointSites?.projectSiteIds
          ?.split(',')
          .map((id) => id.trim())
          .filter((id) => !!id) ?? [],
        this.form.value.customerNumber ?? ''
      )
      .pipe(first());

    const orgaUpdate = this.formAsOrgaDto();
    this.loading = true;
    if (this.isNewOrga) {
      // TODO CE-6234 This is a quickfix to remove the error banner.
      // Remove the disabled in template too
      forkJoin({
        orga: this.organizationService.createOrga(orgaUpdate).pipe(first()),
      }).subscribe({
        next: ({ orga }: { orga: OrganizationDetailResponse }) => {
          this.organizationDetailService.init(orga.id);
          this.orgaId = orga.id;
          this.snackbar.add({
            severity: 'success',
            detail: 'X.MESSAGE.SUCCESS.DETAIL.SAVE',
            summary: 'X.MESSAGE.SUCCESS.SUMMARY',
          });

          this.confirmDialog.open({
            title: this.translate.instant('ORGANIZATION.DETAIL.INFO.DIALOG.ASSIGN_OWNER.TITLE') as string,
            message: this.translate.instant('ORGANIZATION.DETAIL.INFO.DIALOG.ASSIGN_OWNER.TEXT') as string,
            yes: this.translate.instant('ORGANIZATION.DETAIL.INFO.DIALOG.ASSIGN_OWNER.CONFIRM') as string,
            no: this.translate.instant('ORGANIZATION.DETAIL.INFO.DIALOG.ASSIGN_OWNER.REJECT') as string,
            confirmCallback: () => {
              this.loading = false;
              void this.router.navigate(['user', 'user-invite']);
            },
            rejectCallback: () => {
              this.loading = false;
              void this.router.navigate(['organization', orga.id, 'info']);
            },
          });
        },
        error: (error: ApiErrorResponse) => {
          if (error.error.errorCode === 'GEAID_11_0001' && error.error.message.includes('Organization name already exists')) {
            error.error.errorCode = 'GEAID_11_0001_1';
          }
          this.errorHandler.handleError(error);
          this.loading = false;
        },
      });
    } else {
      forkJoin({
        sharepoint: sentSharepointIds$,
        orga: this.organizationDetailService.save(orgaUpdate),
      }).subscribe({
        next: () => {
          this.snackbar.add({
            severity: 'success',
            detail: 'X.MESSAGE.SUCCESS.DETAIL.UPDATE',
            summary: 'X.MESSAGE.SUCCESS.SUMMARY',
          });
          this.loading = false;
        },
        error: (error: ApiErrorResponse) => {
          this.errorHandler.handleError(error);
          this.loading = false;
        },
      });
    }
  }

  protected deleteOrga() {
    this.confirmService.open({
      title: 'X.BUTTON.DELETE',
      message: 'X.PROMPT.DELETE_CONFIRM.SUMMARY',
      yes: 'X.BUTTON.CONFIRM',
      no: 'X.BUTTON.CANCEL',
      buttonTypeYes: 'cancel-red',
      confirmCallback: () => this.removeOrga(),
    });
  }

  private removeOrga() {
    this.loading = true;
    this.organizationService
      .deleteOrga(this.orgaId)
      .pipe(
        first(),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: () => {
          this.snackbar.add({
            severity: 'success',
            detail: 'X.MESSAGE.SUCCESS.DETAIL.DELETE',
            summary: 'X.MESSAGE.SUCCESS.SUMMARY',
          });
          void this.router.navigate(['organization']);
        },
        error: (error: ApiErrorResponse) => {
          this.errorHandler.handleError(error);
        },
      });
  }

  private formAsOrgaDto(): OrganizationDetail {
    return {
      name: this.form.value.orgaName ?? '',
      type: this.form.value.type ?? OrganizationType.OTHER,
      customerNumber: this.form.value.customerNumber ?? '',
      shippingAddress: {
        street: this.form.value.shippingAddress?.street ?? '',
        country: this.form.value.shippingAddress?.country ?? '',
        city: this.form.value.shippingAddress?.city ?? '',
        zipCode: this.form.value.shippingAddress?.zipCode ?? '',
      },
      isBillingAddressSame: !!this.form.value.billingAddressSame,
      billingAddress: this.form.value.billingAddressSame
        ? undefined
        : {
            street: this.form.value.billingAddress?.street ?? '',
            country: this.form.value.billingAddress?.country ?? '',
            city: this.form.value.billingAddress?.city ?? '',
            zipCode: this.form.value.billingAddress?.zipCode ?? '',
          },
    };
  }

  private fillForm(orga: OrganizationDetailResponse) {
    this.form.patchValue({
      orgaName: orga.name,
      type: orga.type,
      customerNumber: orga.customerNumber,
      shippingAddress: {
        zipCode: orga.shippingAddress.zipCode,
        city: orga.shippingAddress.city,
        country: orga.shippingAddress.country,
        street: orga.shippingAddress.street,
      },
      billingAddress: {
        zipCode: orga.billingAddress?.zipCode ?? '',
        city: orga.billingAddress?.city ?? '',
        country: orga.billingAddress?.country ?? 'DE',
        street: orga.billingAddress?.street ?? '',
      },
      billingAddressSame: orga.billingAddressSame,
    });
    this.form.updateValueAndValidity();
  }

  protected linkCustomer(customerNumber: string) {
    this.form.patchValue({ customerNumber: customerNumber });
  }

  private openCustomerSelection() {
    this.complexDialogService.open(
      {
        title: 'ORGANIZATION.DETAIL.INFO.CUSTOMER.MODAL.TITLE',
        yes: 'ORGANIZATION.DETAIL.INFO.CUSTOMER.MODAL.YES',
        no: 'X.BUTTON.CANCEL',
        closable: true,
        data: undefined,
        confirmCallback: (data) =>
          this.confirmCustomer(
            data as {
              customerNumber: string;
              customer: Customer;
            }
          ),
      },
      CustomerSelectionComponent
    );
  }

  private confirmCustomer(customerData: { customerNumber: string; customer: Customer }) {
    if (customerData && customerData.customerNumber && customerData.customer) {
      const customer = customerData.customer;
      this.form.patchValue({
        customerNumber: customerData.customerNumber,
        orgaName: customer.name,
        shippingAddress: customer.address,
      });
      this.form.controls.type.markAsDirty();
      this.form.controls.type.markAsTouched();
      this.form.controls.type.setErrors({ required: true });
      this.form.controls.type.setValue(null);
      this.form.controls.type.updateValueAndValidity();
    }
  }
}
