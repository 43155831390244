import {
  ColumnDefinition,
  ColumnRendererComponent,
  ContextMenuComponent,
  DateRendererComponent,
} from '@gea/digital-ui-lib';
import { IAsset } from './application.model';

export const columnDefinitions: ColumnDefinition[] = appendSettings([
  createTextColumn('X.LABEL.NAME', 'name', 300,),
  createTextColumn('X.LABEL.TYPE', 'type', 150),
  createDateColumn('X.LABEL.UPDATED_ON', 'modifiedAt'),
  createTextColumn('X.LABEL.UPLOADED.BY', 'modifiedBy', 300),
]);

function createTextColumn(displayName: string, key: string, minWidth?: number) {
  return {
    displayName,
    minWidth,
    key,
    sortable: false,
  };
}

function createDateColumn(displayName: string, key: string) {
  return {
    displayName,
    key,
    minWidth: 250,
    sortable: false,
    renderer: {
      component: DateRendererComponent as ColumnRendererComponent<unknown>,
      config: {
        format: 'short',
        locale: 'en-US',
      },
    },
  };
}

function appendSettings(columns: ColumnDefinition[]): ColumnDefinition[] {
  return [
    ...columns,
    {
      displayName: '',
      key: 'actions',
      resizeable: false,
      frozen: 'right',
      sortable: false,
      width: 50,
      renderer: {
        component : ContextMenuComponent as ColumnRendererComponent<IAsset> ,
        config: {
          items: [
            {
              icon: "16px_download",
              text: "X.LABEL.DOWNLOAD",
              action: "download_asset",
            },
            {
              icon: "16px_remove",
              text: "X.LABEL.DELETE",
              action: "remove_asset",
            },
          ],
        },
      },
   
    },
   
  ];
}
