import { map, Observable } from 'rxjs';
import { ApiService, FilterTableSettings, ListResponse } from '@gea/digital-ui-lib';
import { Injectable } from '@angular/core';
import { IApplication, IAsset, NewApplication } from '../models/application.model';
import { CustomParamService } from './param.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationApiService {
  url = 'registration-service/v1/apps';
  apiVersion?: string;

  constructor(
    private api: ApiService,
    private paramService: CustomParamService
  ) {}

  getAllApps(tableFilter: FilterTableSettings): Observable<ListResponse<IApplication[]>> {
    const params = this.paramService.getParams(tableFilter);
    return this.api.get<IApplication[]>(`${this.url}`, { params }, this.apiVersion).pipe(map(this.mapToApplicationstDto));
  }

  setUrl(url?: string) {
    this.url = url ?? this.url;
    return this;
  }

  private mapToApplicationstDto = (dto: IApplication[]): ListResponse<IApplication[]> => {
    if (!dto) {
      return { entryCount: 0, pageEntries: [] } as ListResponse<IApplication[]>;
    }
    return { entryCount: dto.length, pageEntries: dto } as ListResponse<IApplication[]>;
  };

  create(application: NewApplication): Observable<IApplication> {
    return this.api.post<IApplication>(`${this.url}`, application, {}, this.apiVersion);
  }

  find(id: string) {
    return this.api.get<IApplication>(`${this.url}/${id}`);
  }
  delete(id: string): Observable<IApplication> {
    return this.api.delete<IApplication>(`${this.url}/${id}`, {}, this.apiVersion);
  }

  update(id: string, application:Partial<IApplication>): Observable<IApplication> {
    return this.api.put<IApplication>(
      `${this.url}/${id}`,
      { ...application },
      {},
      this.apiVersion
    );
  }
  setApiVersion(apiVersion?: string) {
    this.apiVersion = apiVersion ?? this.apiVersion;
    return this;
  }

  getAllAppAssets(id:string): Observable<IAsset[]> {
    return this.api.get<IAsset[]>(`${this.url}/${id}/assets`, { }, this.apiVersion);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createAsset(id:string ,formData:FormData): Observable<IAsset> {
    return this.api.post<IAsset>(`${this.url}/${id}/assets`, formData, {},this.apiVersion,false);
  }



  findAsset(id:string ,assetId: string): Observable<IAsset> {
    return this.api.get<IAsset>(`${this.url}/${id}/assets/${assetId}`, {}, this.apiVersion);
  }
  deleteAsset(id: string,assetId: string): Observable<IAsset> {
    return this.api.delete<IAsset>(`${this.url}/${id}/assets/${assetId}`, {}, this.apiVersion);
  }
}
