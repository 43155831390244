import { distinctUntilChanged } from 'rxjs/operators';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ComplexDialogV2Service, Customer, DialogV2Service } from '@gea/digital-ui-lib';
import { of, startWith, switchMap } from 'rxjs';
import { CustomerSelectionComponent } from './customer-selection';
import { CustomerAdminApiService } from '../../../../services/customer-admin-api.service';

@Component({
  selector: 'gea-id-customer-link',
  templateUrl: './customer-link.component.html',
  styleUrls: ['./customer-link.component.scss'],
})
export class CustomerLinkComponent implements OnInit {
  private _customerNumber = '';
  @Output() customerNumberChanged = new EventEmitter<string>();
  @Input() disabled = false;
  @Input() loading = false;

  get customerNumber(): string {
    return this._customerNumber;
  }

  @Input({ required: true })
  set customerNumber(value: string) {
    this._customerNumber = value;
    this.customerNumberChanged.emit(value);
  }

  protected customer?: Customer;

  constructor(
    private customerAdminService: CustomerAdminApiService,
    private complexDialogService: ComplexDialogV2Service,
    private confirmDialogService: DialogV2Service
  ) {}

  ngOnInit() {
    this.customerNumberChanged
      .pipe(
        startWith(this._customerNumber),
        distinctUntilChanged(),

        switchMap((customerNumber) => {
          if (customerNumber) {
            return this.customerAdminService.getCustomer(customerNumber);
          }
          return of(undefined);
        })
      )
      .subscribe((customer) => {
        this.customer = customer;
      });
  }

  protected openCustomerSelection() {
    this.complexDialogService.open(
      {
        title: 'ORGANIZATION.DETAIL.INFO.CUSTOMER.MODAL.TITLE',
        yes: 'ORGANIZATION.DETAIL.INFO.CUSTOMER.MODAL.YES',
        no: 'X.BUTTON.CANCEL',
        closable: true,
        data: this._customerNumber,
        confirmCallback: (data) =>
          this.confirmCustomer(
            data as {
              customerNumber: string;
              customer: Customer;
            }
          ),
      },
      CustomerSelectionComponent
    );
  }

  private confirmCustomer(customerData: { customerNumber: string; customer: Customer }): void {
    if (customerData?.customerNumber) {
      this.customerNumber = customerData.customerNumber;
      return;
    }
    this.complexDialogService.close();

    if (this.customerNumber) {
      // timeout is necessary, to ensure that the dialog is closed before the confirm dialog is opened
      setTimeout(() => {
        this.confirmDialogService.open({
          title: 'ORGANIZATION.DETAIL.INFO.DELETE.CONFIRM.TITLE',
          no: 'X.BUTTON.CANCEL',
          yes: 'X.BUTTON.CONTINUE',
          message: 'ORGANIZATION.DETAIL.INFO.DELETE.CONFIRM.MESSAGE',
          confirmCallback: () => {
            this.customerNumber = customerData.customerNumber;
          },
        });
      });
    }
  }
}
