import { Injectable } from '@angular/core';

import { ApiService, ApiServiceInterface } from '@gea/digital-ui-lib';

import { ProjectName } from './models';

@Injectable({
  providedIn: 'root',
})
export class TranslationApiService implements ApiServiceInterface {
  url = 'translation-service/v1/translations'; // e.g. https://api.apps.dev.gea.com/portal-admin/translation
  apiVersion?: string;

  constructor(private api: ApiService) {
    this.setUrl(this.url);
  }

  deploy(projectName: ProjectName) {
    return this.api.put(this.url + '/deploy', { projectName }); // e.g. https://api.apps.dev.gea.com/portal-admin/translation/deploy
  }

  setUrl(url?: string) {
    if (url) {
      this.url = url ?? this.url;
    }
    return this;
  }

  setApiVersion(apiVersion?: string) {
    this.apiVersion = apiVersion ?? this.apiVersion;
    return this;
  }
}
