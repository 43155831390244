import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationService } from '../../services/application.service';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterOutlet } from '@angular/router';
import { ButtonV2Module, ContentLayoutComponent, TableV2Module, PageHeaderModule, TabMenuV2Module, PageContentLayoutComponent, InputV2Module, SelectV2Module, LinkV2Module, PageFooterComponent, RadioGroupV2Module, SelectOption, DialogV2Service, SnackbarService, ApiErrorResponse, ErrorHandlerV2Service } from '@gea/digital-ui-lib';
import { TranslateModule } from '@ngx-translate/core';
import { IApplication } from '../../models/application.model';
import { AppType, Division } from '../../models/division-type.model';
import { finalize, first } from 'rxjs';

@Component({
  selector: 'gea-id-workspace-application-info',
  standalone: true,
  imports: [CommonModule,CommonModule, 
     ButtonV2Module,
    ContentLayoutComponent,
    TableV2Module,
    TranslateModule,
    PageHeaderModule,
    TabMenuV2Module,
    ContentLayoutComponent,
    PageContentLayoutComponent,
    RouterOutlet,
    ReactiveFormsModule,
    InputV2Module,
    SelectV2Module,
    LinkV2Module,
    RadioGroupV2Module,
    PageContentLayoutComponent,
    PageFooterComponent,

  ],
  templateUrl: './application-info.component.html',
  styleUrl: './application-info.component.scss',
})
export class ApplicationInfoComponent implements OnInit {
  @Input({ required: true }) appId = '';
  loading = true;

  constructor ( private applicationService : ApplicationService,
    private confirmService: DialogV2Service,
    private fb :FormBuilder,
    private snackbar: SnackbarService,
    private router: Router,
    private errorHandler: ErrorHandlerV2Service,
    private cdr: ChangeDetectorRef
  ){}
  protected divisionTypes: SelectOption<string>[] = Object.keys(Division).map((key) => ({ value: key, name: key }));
  protected appTypesOptions: SelectOption<string>[] = Object.keys(AppType).map((key) => ({ value: key, name: key }));
  protected verificationOptions = [
    { value: true, name: 'true' },
    { value: false, name: 'false' },
  ];

  protected selected : | undefined;
  protected currentApplication!: IApplication  ;

  protected form = this.fb.group({
    name: ['',Validators.required ],
    appKey:['',Validators.required],
    appType:[''],
    imagePath: ['', ],
    verificationNeeded: [false, ],
    hasTranslation: [false, ],
  });
  ngOnInit() {    
    this.applicationService.find(this.appId).subscribe((application:IApplication)=>{
      this.fillForm(application);
      this.currentApplication = application;
      this.cdr.detectChanges();
      this.loading = false;
    });
  }
  fillForm(application: IApplication) {
    
   this.form.setValue({ 
    name:application.displayName,
    appKey:application.appKey,
    appType:application.appType,
    imagePath:application.imagePath,
    verificationNeeded: application.verificationNeeded,
    hasTranslation:application.hasTranslation
   })
   
   this.form.updateValueAndValidity();


  }

 

  protected deleteApp() {
    this.confirmService.open({
      title: 'X.BUTTON.DELETE',
      message: 'X.PROMPT.DELETE_CONFIRM.SUMMARY',
      yes: 'X.BUTTON.CONFIRM',
      no: 'X.BUTTON.CANCEL',
      buttonTypeYes: 'cancel-red',
      confirmCallback: () => this.removeApp(),
    });
  }

  private removeApp() {
    this.loading = true;
    this.applicationService
      .delete(this.appId)
      .pipe(
        first(),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: () => {
          this.snackbar.add({
            severity: 'success',
            detail: 'X.MESSAGE.SUCCESS.DETAIL.DELETE',
            summary: 'X.MESSAGE.SUCCESS.SUMMARY',
          });
         void this.router.navigate(['applications']);
        },
        error: (error: ApiErrorResponse) => {
          this.errorHandler.handleError(error);
        },
      });
  }


  protected save() {
    this.form.updateValueAndValidity();
    if (this.form.invalid) {
      throw new Error('Form is invalid');
    }
    const appUpdate = {
      displayName:this.form.value.name ?? '',
      appKey:this.form.value.appKey ?? '',
      imagePath: this.form.value.imagePath ?? '',
      verificationNeeded: this.form.value.verificationNeeded ?? false,
      hasTranslation: this.form.value.hasTranslation ?? false,
    };
    this.loading = true;

      this.applicationService.update(this.appId,appUpdate).subscribe({
        next: () => {
          this.snackbar.add({
            severity: 'success',
            detail: 'X.MESSAGE.SUCCESS.DETAIL.UPDATE',
            summary: 'X.MESSAGE.SUCCESS.SUMMARY',
          });
          this.loading = false;
        },
        error: (error: ApiErrorResponse) => {
          this.errorHandler.handleError(error);
          this.loading = false;
        },
      });
    }
  

}
