import { Injectable } from '@angular/core';
import { BehaviorSubject, first, tap } from 'rxjs';
import { IFeatureFlagAssignment, NewFeatureFlagAssignment } from '../models/feature-flag-assignment.model';
import { FeatureFlagApiService } from './feature-flag-api-service';
import { FilterTableSettings, ListResponse } from '@gea/digital-ui-lib';

@Injectable({ providedIn: 'root' })
export class FeatureFlagService {
  private _featureFlags$ = new BehaviorSubject<ListResponse<IFeatureFlagAssignment[]>>({
    entryCount: 0,
    pageEntries: [],
  });

  get featureFlags$() {
    return this._featureFlags$.asObservable();
  }

  get featureFlags() {
    return this._featureFlags$.getValue();
  }

  constructor(private featureFlagApiService: FeatureFlagApiService) {}

  init(tableFilter: FilterTableSettings) {
    return this.featureFlagApiService
      .getAllFeatureFlagAssignments(tableFilter)
      .pipe(first())
      .pipe(
        tap((data) => {
          this._featureFlags$.next(data);
        })
      );
  }

  add(flag: NewFeatureFlagAssignment) {
    return this.featureFlagApiService.create(flag).pipe(
      tap({
        next: (result) => {
          this._featureFlags$.next({
            pageEntries: [
              {
                ...result,
                featureFlagDisplayName: flag.featureFlag?.displayName ?? '', // FIXME Should not be necessary, fix in backend
              },
              ...this.featureFlags.pageEntries,
            ],
            entryCount: this.featureFlags.entryCount + 1,
          });
        },
      })
    );
  }

  update(id: string, flag: NewFeatureFlagAssignment) {
    if (!id) throw Error('Tried editing Feature Flag without an id');

    return this.featureFlagApiService.update(id, flag).pipe(
      tap({
        next: (response) => {
          const flags = this.featureFlags.pageEntries;
          const index = flags.findIndex((assignment) => assignment.id === id);

          // TODO this should not be necessary. response should contain this data
          flags[index] = {
            ...response,
            featureFlagDisplayName: flag.featureFlag?.displayName ?? '',
            createdAt: flags[index].createdAt,
            createdBy: flags[index].createdBy,
          };
          this._featureFlags$.next({ pageEntries: flags, entryCount: this.featureFlags.entryCount });
        },
      })
    );
  }

  delete(id: string) {
    return this.featureFlagApiService.delete(id).pipe(
      tap({
        next: () => {
          const flags = this.featureFlags.pageEntries;
          const index = flags.findIndex((assignment) => assignment.id === id);
          flags.splice(index, 1);
          this._featureFlags$.next({
            entryCount: this.featureFlags.entryCount - 1,
            pageEntries: flags,
          });
        },
      })
    );
  }
}
