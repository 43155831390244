<geaui-page-content-layout>
  <ng-container page-content>
    <div class="memberships-container geaui-grid geaui-sidebar-grid geaui-scrollable-table">
      <div
        class="gea-font-heading-20 align-center geaui-col-desktop-xl-start-7 geaui-col-desktop-xl-10 geaui-col-desktop-md-10 geaui-col-tablet-lg-10 geaui-col-tablet-sm-10 geaui-col-phone-lg-6 geaui-col-phone-sm-3"
        data-cy="title">
        {{ 'X.LABEL.MEMBERSHIPS' | translate }}
      </div>

      <div
        class="geaui-col-desktop-xl-start-7 geaui-col-desktop-xl-2 geaui-col-desktop-md-2 geaui-col-tablet-lg-2 geaui-col-tablet-sm-2 geaui-col-phone-lg-2 geaui-col-phone-sm-1">
        <geaui-button-v2
          class="add-button"
          [disabled]="(hasNoUpdatePermission | async)! || loading"
          (click)="openAddMemberShipDialog()"
          preIcon="16px_e-add">
          {{ 'X.BUTTON.ADD' | translate }}
        </geaui-button-v2>
      </div>

      <geaui-hierarchy-table-v2
        class="geaui-col-desktop-xl-start-7 geaui-col-desktop-xl-12 geaui-col-desktop-lg-12 geaui-col-desktop-md-12 geaui-col-tablet-lg-12 geaui-col-tablet-sm-12 geaui-col-phone-lg-8 geaui-col-phone-sm-4"
        [tableId]="userDetailService.tableIdMemberships"
        [columnDefinitions]="columnDefinitions"
        [data]="membershipTableData"
        [totalRecords]="totalRecords"
        [sortable]="true"
        [loading]="loading"
        [rowsClickable]="(hasNoUpdatePermission | async) === false"
        [loadingSubLevel]="loadingSubLevel"
        (onNodeExpand)="onNodeExpand($event)"
        (rowClicked)="openEditMemberShipDialog($event)">
      </geaui-hierarchy-table-v2>
    </div>
  </ng-container>
</geaui-page-content-layout>
