<geaui-page-content-layout>
  <ng-container page-content>
    <form [formGroup]="form">
      <section class="geaui-grid geaui-sidebar-grid">
        <div class="gea-font-heading-20 align-center geaui-col-desktop-md-start-5 geaui-col-desktop-md-4">
          {{ 'X.LABEL.PERSONAL_DATA' | translate }}
        </div>
        <geaui-input-v2
          class="geaui-col-desktop-md-start-5 geaui-col-desktop-md-4"
          [formName]="FORM_CONTROL_FIRSTNAME"
          [loading]="isLoading"
          [group]="form"
          [label]="'X.LABEL.FIRST_NAME' | translate"
          [required]="true"></geaui-input-v2>
        <geaui-input-v2
          class="geaui-col-desktop-md-start-5 geaui-col-desktop-md-4"
          [formName]="FORM_CONTROL_LASTNAME"
          [loading]="isLoading"
          [group]="form"
          [label]="'X.LABEL.LAST_NAME' | translate"
          [required]="true"></geaui-input-v2>
        <geaui-input-v2
          class="geaui-col-desktop-md-start-5 geaui-col-desktop-md-4"
          [formName]="FORM_CONTROL_EMAIL"
          [loading]="isLoading"
          [group]="form"
          [label]="'X.LABEL.EMAIL' | translate"
          [required]="true">
        </geaui-input-v2>
        <geaui-autocomplete-select-v2
          class="geaui-col-desktop-md-start-5 geaui-col-desktop-md-4"
          [formControlName]="FORM_CONTROL_LANGUAGE"
          [loading]="isLoading"
          [label]="'X.LABEL.LANGUAGE' | translate"
          [options]="languageOptions"
          [required]="true">
        </geaui-autocomplete-select-v2>
        <geaui-select-v2
          class="geaui-col-desktop-xl-start-11 geaui-col-desktop-md-start-5 geaui-col-desktop-md-4"
          [label]="'X.LABEL.CUSTOMER_USER_TYPE' | translate"
          [group]="form"
          [options]="customerTypeOptions"
          [required]="true"
          [loading]="isLoading"
          formName="customerUserType"></geaui-select-v2>
        <div class="geaui-col-desktop-xl-start-11 geaui-col-desktop-md-start-9 geaui-col-desktop-md-1 customer-type">
          <geaui-icon-v2
            class="icon"
            [pTooltip]="'USERS.USER_INVITE.FORM.SETTINGS.CUSTOMER_USER_TYPE.INFO' | translate"
            file="16px_c-info"
            tooltipPosition="right"
            tooltipStyleClass="p-tooltip-light-blue p-tooltip-arrow-position-start"></geaui-icon-v2>
        </div>
        <geaui-autocomplete-select-v2
          class="geaui-col-desktop-md-start-5 geaui-col-desktop-md-4"
          [formControlName]="FORM_CONTROL_DASHBOARD"
          [loading]="isLoading"
          [label]="'USERS.USER_INVITE.FORM.SETTINGS.DASHBOARD.LABEL' | translate"
          [options]="dashboardOptions">
        </geaui-autocomplete-select-v2>
      </section>
    </form>
  </ng-container>
</geaui-page-content-layout>
