<geaui-page-content-layout [useDefaultPadding]="false">
  <ng-container page-header>
    <geaui-page-header [withSidebar]="true" [pageTitle]="assetTitle" (backArrowClicked)="routeAppAssets()">
    </geaui-page-header>

    <div class="image-container">
      <img [src]="imageLink" />
    </div>

  </ng-container>
</geaui-page-content-layout>