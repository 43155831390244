import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import {
  BackofficeApiService, BackofficeMembershipHistoryResponse,
  ListBackofficeMembershipRequestParams,
  MembershipResponse,
  RoleResponse,
} from '@gea-id/shared';
import {
  ListResponse,
  SortDirection,
} from '@gea/digital-ui-lib';

@Injectable({
  providedIn: 'root',
})
export class BackofficeMembershipService {
  constructor(public backofficeApiService: BackofficeApiService) {}

  getTempMembership(): Observable<MembershipResponse> {
    return this.backofficeApiService.getBackofficeMembership();
  }

  listTempMembership(): Observable<ListResponse<BackofficeMembershipHistoryResponse[]>> {
    const params: ListBackofficeMembershipRequestParams = {
      page:"0",
      pageSize: "5",
      sortBy: 'createdAt',
      sortDir: SortDirection.DESCENDING
    }
    return this.backofficeApiService.listBackofficeMembership(params)
  }

  updateTempMembership(organizationId: string, role: string, language: string, appId: string): Observable<unknown> {

      return this.backofficeApiService.updateBackofficeMembership({
        organizationId,
        roleId: role,
        language,
        appId
      });
  }

  getTempMembershipRoles(): Observable<RoleResponse[]> {
    return this.backofficeApiService.getPossibleRoles();
  }
}
