import { Component } from '@angular/core';

import { ColumnRendererComponent } from '@gea/digital-ui-lib';

import { TranslationDeployment } from '../translation-files-api.service';

@Component({
  selector: 'gea-id-workspace-appname-renderer',
  templateUrl: './appname-renderer.component.html',
})
export class AppnameRendererComponent implements ColumnRendererComponent<TranslationDeployment> {
  rowData?: TranslationDeployment;

  getKey(): string {
    let appName = this.rowData?.app ?? 'UNKNOWN';
    appName = appName.replace('App', '');
    if (appName === 'assets') {
      appName = 'ASSET';
    }
    return 'ADMINISTRATION_TASKS.APP_OPTIONS.' + appName.toUpperCase();
  }
}
