import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import {
  ApiErrorResponse,
  App,
  AutocompleteSelectV2Component,
  ColumnDefinition,
  ErrorHandlerV2Service,
  ResourcesSelectOptionsModel,
  SelectOption,
  SnackbarService,
  TableServiceV2,
} from '@gea/digital-ui-lib';
import { filter, first, map, Observable, of, take, tap } from 'rxjs';

import { AppService } from '../services/app.service';
import {
  BackofficeMembershipHistoryResponse,
  CultureService,
  OrganizationAdminListItemResponse,
  OrganizationService,
  OrganizationsList200Response,
  RoleItemResponse,
} from '@gea-id/shared';
import { BackofficeMembershipService } from '../services';
import { temporaryMembershipsColumns } from './models/temporary-memberships-columns.config';

@Component({
  selector: 'gea-id-workspace-create-temporary-membership',
  templateUrl: './temporary-membership.component.html',
  styleUrls: ['./temporary-membership.component.scss'],
})
export class TemporaryMembershipComponent implements OnInit {
  devLanguageOption: SelectOption<string> = {
    name: 'Developer',
    value: 'dv-DV',
    nameKey: 'X.LANGUAGE.DV-DV',
  };
  protected orgas: SelectOption<string>[] = [];
  public languageOptions$?: Observable<SelectOption<string>[]>;
  public languageOptions: SelectOption<string>[] = [];
  public startingApps: SelectOption<string>[] = [];
  public apps: App[] = [];
  public roles$?: Observable<SelectOption<string>[]>;
  public emptyMessageKey?: string;
  protected loading = true;

  //table
  TABLE_ID = 'admin-temp-membership-history-table';
  columnDefinitions: ColumnDefinition[] = temporaryMembershipsColumns;
  tempMemberships: BackofficeMembershipHistoryResponse[] = [];
  totalRecords = 0;

  readonly GEA_READ_ONLY_ID = '1f788ac5-e560-4165-837b-fd90b034f24d';

  public formGroup = this.formBuilder.group({
    organization: ['', [Validators.required]],
    role: ['', [Validators.required]],
    language: ['', [Validators.required]],
    startingApp: ['', Validators.required],
  });

  @ViewChild('orga') autocompleteOrga!: AutocompleteSelectV2Component<unknown>;

  constructor(
    private snackbar: SnackbarService,
    private errorHandlerService: ErrorHandlerV2Service,
    private backofficeMembershipService: BackofficeMembershipService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private appService: AppService,
    private cultureService: CultureService,
    private orgaService: OrganizationService,
    private tableService: TableServiceV2
  ) {}

  ngOnInit(): void {
    // create a formGroup and initially set the roles field disabled
    // get options for the dropdowns
    this.fetchStaticData();

    this.appService
      .getTempAccessApps()
      .pipe(
        take(1),
        tap((apps) => (this.apps = apps)),
        tap(() => this.fetchBackofficeMembershipHistory())
      )
      .subscribe((apps: App[]) => {
        this.startingApps = apps
          .filter((app) => app.url)
          .map(
            (app) =>
              ({
                nameKey: `APPS.${app.appKey}.NAME`,
                value: app.url,
              }) as SelectOption<string>
          )
          .sort((a, b) => this.translatedSort(a.nameKey, b.nameKey));
        const preselectedApp = apps.find((app: App) => app.appKey === 'CORE_PORTAL')?.url ?? '';
        this.formGroup.controls.startingApp.patchValue(
          this.startingApps.find((option: SelectOption<string>) => option.value === preselectedApp)?.value ?? ''
        );
        this.loading = false;
      });

    this.tableService.actions.pipe(filter((action) => action.tableId === this.TABLE_ID)).subscribe(({ action, rowData }) => {
      const row: BackofficeMembershipHistoryResponse = rowData as BackofficeMembershipHistoryResponse;
      switch (action) {
        case 'retrieve':
          this.orgas = [{ name: row.organizationName, value: row.organizationId }];
          this.formGroup.controls.language.patchValue(row.language, { emitEvent: true });
          this.formGroup.controls.role.patchValue(row.roleId, { emitEvent: true });
          this.formGroup.controls.organization.patchValue(row.organizationId, { emitEvent: true });
          this.formGroup.controls.startingApp.patchValue(this.apps.find((app) => app.id === row.appId)?.url ?? '', {
            emitEvent: true,
          });
          // FIXME This works, but is hacky. Problemis that the autocomplete didn't update on a second select.
          this.autocompleteOrga.currentlySelected = {
            name: row.organizationName,
            value: row.organizationId,
          };
          this.formGroup.updateValueAndValidity();
          break;
      }
    });
  }

  private fetchBackofficeMembershipHistory(): void {
    this.loading = true;
    this.backofficeMembershipService.listTempMembership().subscribe((response) => {
      // this.totalRecords = response.entryCount;
      this.tempMemberships = response.pageEntries.map((entry) => {
        return {
          ...entry,
          languageLabel: entry.language.toUpperCase(),
          roleName: entry.roleName.toUpperCase(),
          appKey: `APPS.${this.apps.find((app) => app.id === entry.appId)?.appKey?.toUpperCase() ?? 'UNKNOWN'}.NAME`,
        };
      });
      this.loading = false;
    });
  }

  private fetchStaticData(): void {
    this.cultureService.resourceData$
      .pipe(
        filter((x) => !!x),
        map(
          (res: ResourcesSelectOptionsModel | null) =>
            res?.languageOptions
              ?.filter((option) => option)
              .map(
                (option) =>
                  ({
                    ...option,
                    nameKey: 'X.LANGUAGE.' + option.value.toUpperCase(),
                  }) as SelectOption<string>
              )
              .sort((a, b) => this.translatedSort(a.nameKey, b.nameKey)) ?? []
        )
      )
      .subscribe((options: SelectOption<string>[]) => this.preselectLanguage(options));

    // Use value Changes on orga input to fetch orgas

    this.roles$ = this.backofficeMembershipService.getTempMembershipRoles().pipe(
      map((roles: RoleItemResponse[]) => {
        return roles.map((role: RoleItemResponse) => {
          return {
            nameKey: `X.ROLE.${role.name.toUpperCase()}`,
            value: role.id,
          } as SelectOption<string>;
        });
      }),
      tap((roles) => {
        if (!roles.length) {
          throw new Error('No role defined.');
        }
        // Set default role to gea read only.
        this.formGroup?.controls.role?.setValue(
          (roles.find((x: SelectOption<string>) => x.value === this.GEA_READ_ONLY_ID) as SelectOption<string>).value
        );
        if (roles.length > 1) {
          this.formGroup?.controls.role?.enable();
        } else {
          this.formGroup.controls.role.disable();
        }
      }),
      map((roles: SelectOption<string>[]) => {
        return roles.sort((a: SelectOption<string>, b: SelectOption<string>) => this.translatedSort(a.nameKey, b.nameKey));
      })
    );
  }

  private preselectLanguage(options: SelectOption<string>[]): void {
    const browserLang: readonly string[] = navigator.languages;

    options.push(this.devLanguageOption);
    this.languageOptions = options ?? [];
    const lang =
      browserLang.find((lang) => options.map((option: SelectOption<string>) => option.value).includes(lang)) ?? 'en-US';
    this.formGroup.controls.language.setValue(lang);
  }

  save() {
    const orgaId = this.formGroup.value.organization ?? '';
    this.backofficeMembershipService
      .updateTempMembership(
        orgaId,
        this.formGroup?.value.role as string,
        this.formGroup?.value.language as string,
        this.apps.find((app) => app.url === this.formGroup?.value.startingApp)?.id ?? ''
      )
      .pipe(first())
      .subscribe({
        next: () => {
          // temp membership created successfully - redirect to starting app in new tab
          this.snackbar.add({
            severity: 'success',
            summary: 'X.MESSAGE.SUCCESS.SUMMARY',
            detail: 'X.MESSAGE.SUCCESS.DETAIL.SAVE',
          });
          window.open(this.formGroup.value.startingApp ?? '', '_blank');
          this.fetchBackofficeMembershipHistory();
        },
        error: (httpErrorResponse: ApiErrorResponse) => {
          // temp membership not created - show error message
          if (!httpErrorResponse) {
            this.errorHandlerService.handleError(httpErrorResponse);
          } else {
            this.errorHandlerService.handleWithCustomError(
              this.translate.instant('API.ERROR.CREATE-TEMP-MEMBERSHIP') as string,
              httpErrorResponse
            );
          }
        },
      });
  }

  private translatedSort(a?: string, b?: string) {
    return (this.translate.instant(a ?? '') as string).localeCompare(this.translate.instant(b ?? '') as string);
  }

  public fetchOrgas = (query: string, options: SelectOption<string>[]) => {
    if (query.length < 2) {
      this.emptyMessageKey = 'X.AUTOCOMPLETE-SELECT.EMPTY_MESSAGES.MIN_LENGTH';
      if (!query.length && options.length) {
        return of(options);
      }
      return of([]);
    }

    return this.orgaService
      .getOrganizationsV2({
        page: 0,
        pageSize: 20,
        columns: {
          name: {
            filter: [query],
          },
        },
      })
      .pipe(
        map((response: OrganizationsList200Response) =>
          response.pageEntries.map(
            (orga: OrganizationAdminListItemResponse) =>
              ({
                name: orga.name,
                value: orga.orgaId,
              }) as SelectOption<string>
          )
        ),
        tap((orgas) => {
          this.emptyMessageKey = undefined;
          this.orgas = orgas;
        })
      );
  };
}
