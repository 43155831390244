<geaui-page-content-layout>
  <ng-container page-header>
    <geaui-page-header
      [withSidebar]="true"
      [hasBackArrow]="false"
      pageTitle="ADMINISTRATION_TASKS.TAB_TITLE"
      help="HELP.ADMINISTRATION_TASK.DEPLOY_TRANSLATION.PAGE"></geaui-page-header>
  </ng-container>

  <ng-container page-content>
    <div class="administration-tasks-container geaui-grid geaui-sidebar-grid geaui-scrollable-table" [formGroup]="formGroup">
      <div class="geaui-col-desktop-xl-start-7 geaui-col-desktop-md-4 left-container">
        <div class="form-heading">
          <div class="gea-font-heading-20 title">{{ 'ADMINISTRATION_TASKS.FORM_TITLE' | translate }}</div>
        </div>
        <div class="form-field-apps" *ngIf="formGroup">
          <geaui-select-v2
            id="apps"
            [options]="appOptions || []"
            [label]="'ADMINISTRATION_TASKS.APPS' | translate"
            [group]="formGroup">
          </geaui-select-v2>
        </div>
        <geaui-button-v2 [disabled]="disabled || !getAppId()" [sending]="loading" (click)="save()" preIcon="16px_arrow-right">
          {{ 'ADMINISTRATION_TASKS.DEPLOY_TRANSLATION' | translate }}
        </geaui-button-v2>
      </div>

      <div class="geaui-col-desktop-xl-start-11 geaui-col-desktop-md-8 right-container">
        <div class="gea-font-heading-20">{{ 'ADMINISTRATION_TASKS.LIST_TITLE' | translate }}</div>
        <div class="list-container">
          <gea-id-workspace-latest-deployments [loading]="loading" [highlightRow]="highlightRow">
          </gea-id-workspace-latest-deployments>
        </div>
      </div>
    </div>
  </ng-container>
</geaui-page-content-layout>
