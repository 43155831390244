import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ApiErrorResponse,
  AttachmentsV2Module,
  ButtonV2Module,
  ContentLayoutComponent,
  DialogV2Service,
  ErrorHandlerV2Service,
  InputV2Module,
  PageContentLayoutComponent,
  PageHeaderModule,
  SelectV2Module,
  SideModalV2Module,
  SnackbarMessage,
  SnackbarService,
  TableServiceV2,
  TableV2Module,
  TabMenuV2Module,
  UserFileV2,
} from '@gea/digital-ui-lib';
import { ReactiveFormsModule } from '@angular/forms';
import { Router, RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { columnDefinitions } from '../../models/application-assets-column.config';
import { tap, switchMap, first, finalize } from 'rxjs';
import { ApplicationService } from '../../services/application.service';
import { IAsset } from '../../models/application.model';
import { appRoutes } from '../../models/applications.routing';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'gea-id-workspace-application-assets',
  standalone: true,
  imports: [CommonModule, 
     ButtonV2Module,
    ContentLayoutComponent,
    TableV2Module,
    SideModalV2Module,
    TranslateModule,
    PageHeaderModule,
    TabMenuV2Module,
    ContentLayoutComponent,
    AttachmentsV2Module,
    PageContentLayoutComponent,
    RouterOutlet,
    ReactiveFormsModule,
    InputV2Module,
    SelectV2Module,],
  templateUrl: './application-assets.component.html',
  styleUrl: './application-assets.component.scss',
})
export class ApplicationAssetsComponent  implements OnInit{
  @Input({ required: true }) appId = '';
  showAttachmentSideModal =false
  public data : IAsset[] =[]
  public readonly TABLE_ID = 'applications-assets-table';
  public columnDefinitions = columnDefinitions

  maxFileSizeInBytes = 150000000;
  attachments: UserFileV2[] = [];
  
 loading= false;
  constructor( 
    private router: Router,
    private tableService: TableServiceV2,
    private confirmService: DialogV2Service,
    private applicationService : ApplicationService,
    private snackbar: SnackbarService,
    private errorHandler: ErrorHandlerV2Service,
    private _destroyRef: DestroyRef

  ) {}
  ngOnInit(): void {
    this.tableService.getFilterTableSettings(this.TABLE_ID).pipe(
      tap(() => (this.loading = true)), 
      takeUntilDestroyed(this._destroyRef),
      switchMap(() =>
        this.applicationService.getAssets(this.appId) 
      ),
      tap(() => (this.loading = false)) 
    ).subscribe({
      next: (response) => {
        this.data =response;   
      },
      error: (error:ApiErrorResponse) => {
        this.loading = false;
        this.errorHandler.handleError(error);
      }
    });
    this.applicationService.assets$.pipe(
      takeUntilDestroyed(this._destroyRef)
    ).subscribe({
      next: (updatedAssets) => {
        this.data = updatedAssets; 
      }
    });
    this.tableService.actions.subscribe((action) => {
      const rowData = action.rowData as IAsset;
      if (action.action === 'download_asset') {
        window.open(rowData.url,'_blank', 'noopener,noreferrer')
      }else if (action.action === 'remove_asset'){

        this.confirmService.open({
          title: 'X.BUTTON.DELETE',
          message: 'X.PROMPT.DELETE_CONFIRM.SUMMARY',
          yes: 'X.BUTTON.CONFIRM',
          no: 'X.BUTTON.CANCEL',
          buttonTypeYes: 'cancel-red',
          confirmCallback: () => this.removeAsset(rowData.id),
        });
      
      }
    });
   
  }

  removeAsset(id:string){  this.applicationService.deleteAsset(this.appId,id).pipe(
    first(),
    takeUntilDestroyed(this._destroyRef),
    finalize(() => {
      this.loading = false;
    })
  )
  .subscribe({
    next: () => {
      this.snackbar.add({
        severity: 'success',
        detail: 'X.MESSAGE.SUCCESS.DETAIL.DELETE',
        summary: 'X.MESSAGE.SUCCESS.SUMMARY',
      });
    },
    error: (error: ApiErrorResponse) => {
      this.errorHandler.handleError(error);
    },
  });

  }

  openAdd() { 
    this.showAttachmentSideModal = true
  }
  toggleAttachmentView() {
    this.showAttachmentSideModal = !this.showAttachmentSideModal;
  }



 updateAttachments(attachments: UserFileV2[]) {
    this.attachments = attachments;
  
  }
  uploadFiles(attachments: UserFileV2[]) {

    this.loading = true;  
    attachments.forEach((attachment: UserFileV2) => {
    if (attachment.file) {  
      const fileName = attachment.file.name.toLowerCase();
      const formData = new FormData();
      formData.append('file', attachment.file, attachment.file.name);
      let assetType: 'PICTURE' | 'ICON' | 'VIDEO';

      if (fileName.match(/\.(jpg|jpeg|png|gif)$/)) 
      assetType = 'PICTURE';
      else if (fileName.match(/\.(svg|ico)$/)) 
      assetType = 'ICON';
      else if (fileName.match(/\.(mp4|avi|mov|mkv)$/)) 
      assetType = 'VIDEO';
      else 
      throw new Error('Unsupported file type');

      formData.append('assetType', assetType);
      this.applicationService.addAsset(this.appId, formData).subscribe({
        next: () => {
          this.loading = false;  
          const message: SnackbarMessage = {
            detail: 'X.MESSAGE.SUCCESS.DETAIL.SAVE',
            summary: 'X.MESSAGE.SUCCESS.SUMMARY',
            severity: 'success',
          };
          this.attachments = []
          this.showAttachmentSideModal =false ;
          this.snackbar.add(message);
        },
        error: (err: ApiErrorResponse) => {
   
          this.loading = false;  
          this.errorHandler.handleError(err);
        }
      });
    }
  })

  }
  public edit(rowData: IAsset) {
    void this.router.navigate(['applications', this.appId, appRoutes.ASSETS,rowData.id]);
  }
  
}
