import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule, HEADER_CONFIG } from './app/app.module';
import { environment } from './environments/environment';
import { ENVIRONMENT_CONFIG, EnvironmentConfiguration } from './environments/models/environment.model';

if (environment.production) {
  enableProdMode();
}

void fetch('./assets/environment-config.json')
  .then((resp) => resp.json())
  .then((config: EnvironmentConfiguration) => {
    platformBrowserDynamic([
      { provide: ENVIRONMENT_CONFIG, useValue: config },
      {
        provide: HEADER_CONFIG,
        useValue: {
          redirectUri: config.oAuth.redirectURL,
          postLogoutRedirectUri: config.oAuth.postLogoutRedirectURL,
          clientId: config.oAuth.clientId,
          authority: config.oAuth.authority,
          authorityDomain: config.oAuth.authorityDomain,
          portalURL: config.portal.redirectURL,
          navigateToLoginRequestUrl: false,
          urlsToIntercept: config.interceptorURLs ?? [],
          urls: {
            permission: '/me/permission',
            resources: 'cultures/v1',
          },
        },
      },
    ])
      .bootstrapModule(AppModule)
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  });
