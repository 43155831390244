import { Observable } from 'rxjs';
import { ApiService, FilterTableSettings, ListResponse } from '@gea/digital-ui-lib';
import { Inject, Injectable } from '@angular/core';
import { History } from './models';
import { HttpClient } from '@angular/common/http';
import { MsalBroadcastService } from '@azure/msal-angular';
import { Store } from '@ngxs/store';
import { CustomParamService } from '../users-list/user-detail/user-history-list/services/param.service';

export type EntityArrayResponseType = ListResponse<History[]>;

@Injectable({
  providedIn: 'root',
})
export class HistoryService extends ApiService {
  url = 'history';
  apiVersion?: string;

  constructor(
    @Inject('auditBaseURL') protected override baseUrl: string,
    @Inject('subscriptionKey') protected override subscriptionKey: string,
    protected override msalBroadcast: MsalBroadcastService,
    protected override http: HttpClient,
    protected override store: Store,
    private paramService: CustomParamService
  ) {
    super(baseUrl, subscriptionKey, msalBroadcast, http, store);
  }

  getAllUserHistoryByUser(tableFilter: FilterTableSettings, userId: string): Observable<EntityArrayResponseType> {
    const params = this.paramService.getParams(tableFilter);
    return this.get<ListResponse<History[]>>(`${this.url}/byUser/${userId}`, { params }, this.apiVersion);
  }

  getOrganizationHistory(tableFilter: FilterTableSettings, orgaId: string): Observable<ListResponse<History[]>> {
    const params = this.paramService.getParams(tableFilter);
    return this.get<ListResponse<History[]>>(`${this.url}/byOrganization/${orgaId}`, { params }, this.apiVersion);
  }
}
