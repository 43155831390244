import { Component, DestroyRef, OnInit } from '@angular/core';
import {
  ApiErrorResponse,
  ComplexDialogV2Service,
  ErrorHandlerV2Service,
  FilterTableSettings,
  ListResponse,
  SnackbarMessage,
  SnackbarService,
  TableServiceV2,
  UserService,
} from '@gea/digital-ui-lib';
import { from, Observable, of, switchMap, tap } from 'rxjs';
import { columnDefinitions } from '../models/columns.config';
import { catchError, map, mergeMap, toArray } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ApplicationService } from '../services/application.service';
import { IApplication, NewApplication } from '../models/application.model';
import { ApplicationModalComponent } from '../modal/application-modal.component';
import { appRoutes } from '../models/applications.routing';
import { Router } from '@angular/router';
@Component({
  selector: 'gea-id-applications',
  templateUrl: './applications.component.html',
  styleUrl: './applications.component.scss',
})
export class ApplicationsComponent implements OnInit {
  public readonly TABLE_ID = 'applications-table';
  public applicationsData$: Observable<IApplication[]> = this.applicationService.applications$.pipe(
    map((data) => data.pageEntries)
  );

  public totalRecords$: Observable<number> = this.applicationService.applications$.pipe(map((data) => data.entryCount));
  public columnDefinitions = columnDefinitions;
  public loading = false;
  public userPermissions: string[] = [];

  constructor(
    private applicationService: ApplicationService,
    private tableService: TableServiceV2,
    private userService: UserService,
    private dialogService: ComplexDialogV2Service,
    private snackbarService: SnackbarService,
    private errorHandlerV2Service: ErrorHandlerV2Service,
    private store: Store,
    private router: Router,
    private _destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.applicationsData$ = this.tableService.getFilterTableSettings(this.TABLE_ID).pipe(
      tap(() => (this.loading = true)),
      takeUntilDestroyed(this._destroyRef),
      switchMap((filter: FilterTableSettings) =>
      this.applicationService.init(filter).pipe(map((response: ListResponse<IApplication[]>) => response.pageEntries))
  ),
      switchMap((applications: IApplication[]) =>
        from(applications).pipe(
          mergeMap((application) =>
            this.userService.getUser(application.createdBy).pipe(
              map((user) => ({
                ...application,
                createdBy: user.firstName,
              })),
              catchError(() =>
                of({
                  ...application,
                  createdBy: 'Not Found',
                })
              )
            )
          ),
          toArray()
        )
      ),
      tap(() => (this.loading = false)),
      catchError((error: ApiErrorResponse) => {
        this.errorHandlerV2Service.handleError(error);
        this.loading = false;
        return of([]);
      })
    );
  }

  openAdd() {
    this.dialogService.open(
      {
        title: 'APPLICATIONS_REGISTRATION.ADD.BUTTON.LABEL',
        yes: 'X.BUTTON.ADD',
        no: 'X.BUTTON.CANCEL',
        confirmCallback: (data: unknown) => {
          this.add(data as NewApplication);
        },
      },
      ApplicationModalComponent
    );
  }

  add(data: NewApplication) {
    this.applicationService.add(data).subscribe({
      next: () => {
        const message: SnackbarMessage = {
          detail: 'X.MESSAGE.SUCCESS.DETAIL.SAVE',
          summary: 'X.MESSAGE.SUCCESS.SUMMARY',
          severity: 'success',
        };
        this.snackbarService.add(message);
      },
      error: (error: ApiErrorResponse) => {
        this.errorHandlerV2Service.handleError(error);
      },
    });
  }

  public edit(rowData: IApplication) {
    void this.router.navigate(['applications', rowData.id, appRoutes.BASIC_INFO]);
  }
}
