import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipModule, ReadOnlyChipType ,ButtonV2Module} from '@gea/digital-ui-lib';
import { ISecret } from '../../../models/application.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
type SeverityMessage = {
  severity: ReadOnlyChipType;
  icon: string;
  message: string;
};

@Component({
  selector: 'gea-id-secret-card',
  standalone: true,
  imports: [CommonModule,ChipModule,ButtonV2Module,TranslateModule,NgxSkeletonLoaderModule],
  templateUrl: './secret-card.component.html',
  styleUrl: './secret-card.component.scss',
})

export class SecretCardComponent implements OnInit {
  @Input() secret!:ISecret;
  @Input() loading =false;
  @Input() isSending =false;
  @Output() renew = new EventEmitter() ;
  secretStatus :SeverityMessage={
    severity: 'default',
    icon: '',
    message: ''
  }

  constructor(   private translate: TranslateService,){}
  ngOnInit() { 
    this.secretStatus = this.getSeverity()
   
  }

  onRenew() {
    if (this.secret) {
      this.renew.emit(); 
    }
  }


 formatDate(dateString: string): string {
    const date = new Date(dateString);
    const lang =this.translate.currentLang;
    return date.toLocaleDateString(lang);
  }
  

  getSeverity():SeverityMessage  {
    const currentDate = new Date();
    const expirationDate = new Date(this.secret.endDateTime);
  

    if (expirationDate < currentDate) {
      return {severity : 'error' as ReadOnlyChipType ,icon : "16px_warning-sign",message:this.translate.instant('APPLICATION.SECRET.EXPIRED.LABEL') as string}; 
    }

    const oneMonthFromNow = new Date();
    oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1);
    if (expirationDate <= oneMonthFromNow) {
      return  {severity : 'warning' as ReadOnlyChipType,icon : "16px_time-clock",message:this.translate.instant('APPLICATION.SECRET.EXPIRING.LABEL') as string}; 
    }

    return {severity : 'success' as ReadOnlyChipType,icon : "16px_check",message:this.translate.instant('APPLICATION.SECRET.ONGOING.LABEL') as string}; 
  }
}
