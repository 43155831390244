import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { switchMap, tap } from 'rxjs';
import { ColumnDefinition, TableServiceV2, User, UserMembershipState } from '@gea/digital-ui-lib';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { orgaUsersColumnDefinition } from '../../models/organizations-users.config';
import { Router } from '@angular/router';
import { OrganizationService } from '@gea-id/shared';

type ColumnData = Pick<User, 'id' | 'email' | 'firstName' | 'lastName'> & {
  roleName: string;
  roleStatus: string;
};

@Component({
  selector: 'gea-id-organization-users',
  templateUrl: './organization-users.component.html',
  styleUrl: './organization-users.component.scss',
})
export class OrganizationUsersComponent implements OnInit {
  public TABLE_ID = 'admin-organization-users-table';
  public data: ColumnData[] = [];
  public columnDefinitions: ColumnDefinition[] = orgaUsersColumnDefinition;
  public totalRecords = 0;

  public loading = true;
  @Input() orgaId = '';

  constructor(
    private organizationService: OrganizationService,
    private tableService: TableServiceV2,
    private router: Router,
    private _destoryRef: DestroyRef
  ) {}

  ngOnInit() {
    this.tableService
      .getFilterTableSettings(this.TABLE_ID)
      .pipe(
        takeUntilDestroyed(this._destoryRef),
        tap(() => (this.loading = true)),
        switchMap(() => this.organizationService.getOrgaMemberships(this.orgaId)),
        tap((users) => {
          this.data = users
            .flatMap((dto) => {
              return {
                firstName: dto.user.firstName,
                lastName: dto.user.lastName,
                email: dto.user.email,
                roleName: dto.membership.roleName?.toUpperCase() ?? '',
                roleStatus: dto.membership.inherited ? UserMembershipState.INHERITED : dto.membership.state ?? '',
                id: dto.user.id,
              };
            })
            .sort((a, b) => a.email.localeCompare(b.email));
        }),
        tap(() => (this.loading = false))
      )
      .subscribe();
  }

  edit(user: ColumnData) {
    void this.router.navigate(['user', user.id, 'memberships']);
  }
}
