import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { ContentLayoutComponent, PageContentLayoutComponent, PageHeaderModule } from '@gea/digital-ui-lib';
import { appRoutes } from '../../../models/applications.routing';
import { ApplicationApiService } from '../../../services/application-api-service';

@Component({
  selector: 'gea-id-workspace-asset-item',
  standalone: true,
  imports: [CommonModule,RouterOutlet, ContentLayoutComponent,
    PageContentLayoutComponent,ContentLayoutComponent, PageHeaderModule,],
  templateUrl: './asset-item.component.html',
  styleUrl: './asset-item.component.scss',
})
export class AssetItemComponent implements OnInit {
  @Input({ required: true }) appId = '';
  @Input({ required: true }) assetId = '';
  public imageLink ='';
  public assetTitle =''
  constructor(
    private router: Router,
  private applicationApiService : ApplicationApiService ){}
  ngOnInit() { 
    this.applicationApiService.findAsset(this.appId,this.assetId).subscribe((asset)=>{
      this.imageLink=asset.url
      this.assetTitle=asset.name
    
    });
  }
  routeAppAssets() {
    void this.router.navigate(['applications', this.appId, appRoutes.ASSETS]);
  }
}
