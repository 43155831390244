<section class="status-container" *ngIf="membershipData">
  <div class="status">
    <span>Status</span>
    <div class="status-icon padding-y">
      <geaui-icon-v2 class="icon" [file]="getIconFile()" [color]="'black'"></geaui-icon-v2>
      <span class="status-text">{{ membershipStateTranslateKey | translate }}</span>
    </div>
  </div>
  <div class="status margin-x">
    <span>{{ 'USERS.USER_DETAIL.DIALOG.INVITED_ON' | translate }}</span>
    <span class="padding-y">{{ membershipData.createdAt | date: 'medium' : '' : translateService.currentLang }}</span>
  </div>
</section>
<form class="memberships-dialog-controls" [formGroup]="form">
  <div class="margin-y">
    <geaui-autocomplete-select-v2
      [formControlName]="'organisation'"
      [label]="'USERS.USER_DETAIL.DIALOG.ORGANISATION'"
      [loading]="loading"
      [emptyMessageKey]="emptyMessageKey"
      [preFilter]="fetchOrgas"
      [options]="organisationOptions">
    </geaui-autocomplete-select-v2>
  </div>
  <div class="margin-y">
    <geaui-autocomplete-select-v2
      [formControlName]="'role'"
      [label]="'USERS.USER_DETAIL.DIALOG.ROLE'"
      [loading]="loading"
      [options]="(roleOptions$ | async) ?? []">
    </geaui-autocomplete-select-v2>
  </div>
  @if (membershipData) {
    <div class="invitation-btn-container">
      @if (membershipData?.state !== MembershipState.ACCEPTED && updatePermission) {
        <span
          ><geaui-link-v2 [loading]="loading || invitationUpdating" (click)="onResendInvitation()" preIcon="16px_reset-1">{{
            'USERS.USER_DETAIL.DIALOG.RESEND' | translate
          }}</geaui-link-v2></span
        >
        <span
          ><geaui-link-v2 [loading]="loading || invitationUpdating" (click)="onCancelInvitation()" preIcon="16px_cancel-invite">{{
            'USERS.USER_DETAIL.DIALOG.CANCEL' | translate
          }}</geaui-link-v2></span
        >
      } @else {
        <span
          ><geaui-link-v2
            [preIcon]="'16px_trash-can'"
            [type]="'cancel-red'"
            [loading]="loading || invitationUpdating"
            (click)="showRemoveMembershipDialog()"
            >{{ 'X.BUTTON.DELETE' | translate }}</geaui-link-v2
          >
        </span>
      }
    </div>
  }
</form>
