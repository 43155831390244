import {
  ColumnDefinition,
  ColumnRendererComponent,
  DateRendererComponent,
} from '@gea/digital-ui-lib';

export const columnDefinitions: ColumnDefinition[] = appendSettings([
  createTextColumn('APPLICATION.DIVISION.COLUMN.NAME', 'division', 300),
  createTextColumn('APPLICATION.DISPLAY_NAME.COLUMN.NAME', 'displayName', 300),
  createTextColumn('APPLICATION.APPKEY.COLUMN.NAME', 'appKey', 300),
  createTextColumn('X.LABEL.TYPE', 'appType', 300),
  createTextColumn('X.LABEL.URL', 'url', 300),
  createTextColumn('APPLICATION.VERIFICATION_REQUIRED.COLUMN.NAME', 'verificationNeeded', 300),
  createTextColumn('APPLICATION.HAS_TRANSLATION.COLUMN.NAME', 'hasTranslation', 300),
  createDateColumn('X.LABEL.CREATED_DATE', 'createdAt'),
  createTextColumn('X.LABEL.CREATED.BY', 'createdBy', 300),
]);

function createTextColumn(displayName: string, key: string, minWidth?: number) {
  return {
    displayName,
    minWidth,
    key,
    sortable: false,
  };
}

function createDateColumn(displayName: string, key: string) {
  return {
    displayName,
    key,
    minWidth: 250,
    sortable: false,
    renderer: {
      component: DateRendererComponent as ColumnRendererComponent<unknown>,
      config: {
        format: 'short',
        locale: 'en-US',
      },
    },
  };
}

function appendSettings(columns: ColumnDefinition[]): ColumnDefinition[] {
  return [
    ...columns
  ];
}
