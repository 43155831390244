<geaui-page-content-layout>
  <ng-container page-header>
    <geaui-page-header
      [withSidebar]="true"
      [hasBackArrow]="false"
      pageTitle="GENERAL.DASHBOARD"
      help="HELP.DASHBOARD.PAGE"></geaui-page-header>
  </ng-container>

  <ng-container page-content>
    <section class="geaui-grid geaui-sidebar-grid">
      <div class="geaui-col-desktop-xl-start-7 geaui-col-desktop-md-6">
        <span class="gea-font-heading-24">{{ 'DASHBOARD.HEADING.SALUTATION' | translate }}</span>
        <br />
        <span class="gea-font-heading-24">{{ 'DASHBOARD.HEADING.TITLE' | translate }}</span>
        <article>
          <span class="gea-font-heading-20">{{ 'DASHBOARD.EXPLANATION.TITLE' | translate }}</span>
          <p>{{ 'DASHBOARD.EXPLANATION.DETAIL' | translate }}</p>
        </article>
        <article>
          <span class="gea-font-heading-20">{{ 'DASHBOARD.MAIN_FUNCTIONS.TITLE' | translate }}</span>
          <ul>
            <li>{{ 'DASHBOARD.MAIN_FUNCTIONS.LIST_POINT.ONE' | translate }}</li>
            <li>{{ 'DASHBOARD.MAIN_FUNCTIONS.LIST_POINT.TWO' | translate }}.</li>
            <li>{{ 'DASHBOARD.MAIN_FUNCTIONS.LIST_POINT.THREE' | translate }}.</li>
          </ul>
        </article>
      </div>
      <div class="content-container-right geaui-col-desktop-xl-start-13 geaui-col-desktop-md-6">
        <img src="/assets/images/group.png" alt="group" />
      </div>

      <div class="content-container-bottom geaui-col-desktop-xl-start-7 geaui-col-desktop-md-12">
        <span class="gea-font-heading-24">{{ 'DASHBOARD.KEY_FEATURES.TITLE' | translate }}</span>

        <div class="features geaui-col-desktop-xl-start-7 geaui-col-desktop-md-12 geaui-sub-grid">
          @for (feature of features; track feature) {
            <div class="feature geaui-col-desktop-xl-11 geaui-col-desktop-md-5">
              <div class="title-container">
                <geaui-icon-v2 class="icon" [file]="feature.iconFile" [color]="feature.iconColor ?? 'black'"></geaui-icon-v2>
                <span class="gea-font-heading-16">{{ feature.title | translate }}</span>
              </div>
              <p>{{ feature.content | translate }}</p>
            </div>
            <div class="geaui-col-desktop-md-1"></div>
          }
        </div>
      </div>
    </section>
  </ng-container>
</geaui-page-content-layout>
