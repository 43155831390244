import { Component, Input } from '@angular/core';

@Component({
  selector: 'gea-id-workspace-base-widget',
  templateUrl: './base-widget.component.html',
  styleUrls: ['./base-widget.component.scss'],
})
export class BaseWidgetComponent {
  @Input({required: true}) editModeActive!: boolean;
}
