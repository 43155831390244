import { Component, OnInit } from '@angular/core';
import {
  ApiErrorResponse,
  ButtonV2Module,
  ColumnDefinition,
  ColumnRendererComponent,
  ErrorHandlerV2Service,
  PageContentLayoutComponent,
  PageFooterComponent,
  PageHeaderModule,
  SelectOption,
  SelectV2Module,
  TableV2Module,
  TextFilterComponent,
  ToggleV2Module,
} from '@gea/digital-ui-lib';
import { FormBuilder, FormControl } from '@angular/forms';
import {
  UserNotificationsSettings,
  UserNotificationSettingsApiService,
  UserNotificationsSettingsApplication,
  OrganizationNotificationType,
  AppIdValue,
} from '@gea-id/shared';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxRendererComponent } from '../send-notification/renderers/checkbox-renderer/checkbox-renderer.component';

@Component({
  selector: 'gea-id-workspace-portal-notification-settings',
  standalone: true,
  templateUrl: './portal-notification-settings.component.html',
  styleUrl: './portal-notification-settings.component.scss',
  imports: [
    TranslateModule,
    PageContentLayoutComponent,
    PageHeaderModule,
    ToggleV2Module,
    PageFooterComponent,
    ButtonV2Module,
    SelectV2Module,
    TableV2Module,
  ],
})
export class PortalNotificationSettingsComponent implements OnInit {
  TABLE_ID = 'organization-notification-list';
  loading = false;
  apps: SelectOption[] = [];
  settings: UserNotificationsSettings = {} as UserNotificationsSettings;
  selectedApp?: UserNotificationsSettingsApplication;
  tableData: OrganizationNotificationType[] = [];
  allAppsToggleState = false;

  organizationNotificationColumnDefinitions: ColumnDefinition[] = [
    {
      displayName: '',
      key: 'selected',
      sortable: false,
      resizeable: true,
      width: 50,
      renderer: {
        component: CheckboxRendererComponent as ColumnRendererComponent<boolean>,
      },
    },
    {
      displayName: 'Company Name',
      key: 'name',
      sortable: true,
      resizeable: true,
      width: 300,
      filter: {
        component: TextFilterComponent,
      },
    },
    {
      displayName: 'Type',
      key: 'type',
      sortable: true,
      resizeable: true,
    },
    {
      displayName: 'Country',
      key: 'country',
      sortable: true,
      resizeable: true,
      width: 200,
      filter: {
        component: TextFilterComponent,
      },
    },
  ];

  formGroup = this.formBuilder.group({
    apps: new FormControl<SelectOption<string> | null>(null),
  });

  constructor(
    private formBuilder: FormBuilder,
    private api: UserNotificationSettingsApiService,
    private errorhandlerService: ErrorHandlerV2Service
  ) {}

  ngOnInit() {
    this.loading = true;
    this.api.get().subscribe({
      next: (settings) => {
        this.loading = false;
        this.settings = settings;
        this.apps =
          this.settings.applications?.map((app) => ({
            nameKey: 'UI-LIB.NOTIFICATION-SETTINGS.APP.' + app.name,
            value: app.appId,
            disabled: app.notificationTypes.length === 0,
          })) ?? [];
      },
      error: (error: ApiErrorResponse) => {
        this.errorhandlerService.handleError(error);
        this.loading = false;
      },
    });
    this.fetchOrganizationNotificationData();
  }

  selectApp(appId: SelectOption<AppIdValue>) {
    this.selectedApp = this.settings?.applications?.find((app) => app.appId === appId.value.value);
    const systemMaintenance = this.selectedApp?.notificationTypes?.find(
      (notificationType) => notificationType.name === 'SYSTEM_MAINTENANCE'
    );
    if (systemMaintenance) {
      systemMaintenance.active = true;
      systemMaintenance.disabled = true;
    }
  }

  toggleAllApps() {
    this.settings?.applications?.forEach((app) => {
      app.notificationTypes?.forEach((notificationType) => {
        if (!notificationType.disabled) {
          notificationType.active = this.allAppsToggleState;
        }
      });
    });
  }

  setAllAppsToggleState() {
    if (this.selectedApp) {
      const enabledNotificationTypes = this.selectedApp.notificationTypes.filter((nt) => !nt.disabled);

      this.allAppsToggleState = enabledNotificationTypes.every((nt) => nt.active);
    }
  }

  fetchOrganizationNotificationData() {
    this.tableData = [
      {
        selected: false,
        name: 'MilkCo Ltd.',
        type: 'Dairy',
        country: 'USA',
      },
      {
        selected: false,
        name: 'CheeseCraft Inc.',
        type: 'Dairy',
        country: 'France',
      },
      {
        selected: false,
        name: 'DairyDelight Group',
        type: 'Dairy',
        country: 'Germany',
      },
      {
        selected: false,
        name: 'CreamyLand Corp.',
        type: 'Dairy',
        country: 'Netherlands',
      },
      {
        selected: false,
        name: 'LactoseLuxe LLC',
        type: 'Dairy',
        country: 'Australia',
      },
    ];
  }
}
