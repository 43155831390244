<geaui-page-content-layout [useDefaultPadding]="false">
  <ng-container page-content>
    <div class="page-header-content">
      <div class="title gea-font-heading-20">
        {{ 'APPLICATION.DETAIL.PATHS.ASSETS' | translate }}
      </div>

      <geaui-button-v2 preIcon="16px_e-add" (click)="openAdd()"
        routerLinkActive="active">
        {{ 'APPLICATION.ASSETS.ADD.BUTTON.LABEL' | translate }}
      </geaui-button-v2>

    </div>


    <geaui-attachments-v2 [showAttachmentView]="showAttachmentSideModal" [removeFilesOnClose]="false"
      [showSaveButton]="true" [maxFileSize]="maxFileSizeInBytes" [hasPermission]="!loading" [files]="attachments" 
      (filesChanged)="updateAttachments($event)" (closeAttachmentView)="toggleAttachmentView()"
      (saveFilesClicked)="uploadFiles($event)">
    </geaui-attachments-v2>




    <div class="geaui-grid geaui-sidebar-grid geaui-scrollable-table geaui-scrollable-table">
      <geaui-table-v2
        class="geaui-col-desktop-xl-start-7 geaui-col-desktop-xl-12 geaui-col-desktop-lg-12 geaui-col-desktop-md-12 geaui-col-tablet-lg-12 geaui-col-tablet-sm-12 geaui-col-phone-lg-8 geaui-col-phone-sm-4"
        [tableId]="TABLE_ID" [data]="data" [columnDefinitions]="columnDefinitions" [sortable]="true"
        [rowsClickable]="true" (rowClicked)="edit($event)">
      </geaui-table-v2>
    </div>
  </ng-container>

</geaui-page-content-layout>