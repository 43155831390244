import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Translations } from '@gea/digital-ui-lib';
import { BehaviorSubject, Observable, first, forkJoin, map, catchError, of } from 'rxjs';

import { ENVIRONMENT_CONFIG, EnvironmentConfiguration } from '../../environments/models/environment.model';

export type TranslationDeployment = {
  app: string;
  datetime: Date;
};
@Injectable({
  providedIn: 'root',
})
export class TranslationFilesApiService {
  sources: Map<string, string> = new Map<string, string>();
  private _deployments: BehaviorSubject<TranslationDeployment[]> = new BehaviorSubject<TranslationDeployment[]>([]);
  deployments$: Observable<TranslationDeployment[]> = this._deployments.asObservable();

  constructor(
    private http: HttpClient,
    @Inject(ENVIRONMENT_CONFIG) private environment: EnvironmentConfiguration
  ) {
    this.initSources(environment.localizationStorageURL);
    void this.fetchDeployments();
  }

  initSources(baseUrl: string) {
    //TODO: Load apps from registration oder translation service
    this.sources.set('adminApp', baseUrl + 'i18n/1/admin/');
    this.sources.set('assetsApp', baseUrl + 'i18n/1/asset/');
    this.sources.set('common', baseUrl + 'i18n/1/common/');
    this.sources.set('portalApp', baseUrl + 'i18n/1/portal/');
    this.sources.set('supportApp', baseUrl + 'i18n/1/support/');
    this.sources.set('documentApp', baseUrl + 'i18n/1/document/');
  }

  fetchDeployments(): Promise<TranslationDeployment[]> {
    const fileName = 'en-US.json';
    this._deployments.next([]); // reset
    const calls: Observable<TranslationDeployment>[] = [];
    this.sources.forEach((url: string, app: string) => {
      calls.push(
        this.http
          .get<Translations>(`${url ?? ''}${fileName}`, {
            observe: 'response',
            headers: {
              // cache busting
              'Cache-Control': 'no-cache',
              Pragma: 'no-cache',
              Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
            },
          })
          .pipe(
            catchError((error) => {
              // eslint-disable-next-line no-console
              console.error(`Error fetching from ${url}:`, error);
              return of(undefined);
            }),
            map((response) => response?.headers.get('Last-Modified')),
            map((date) => new Date(Date.parse(date ?? ''))),
            map((datetime) => ({ app, datetime }) as TranslationDeployment)
          )
      );
    });
    return new Promise((resolve) => {
      if (calls.length) {
        forkJoin(calls)
          .pipe(first())
          .pipe(
            map((deployments) => {
              return deployments.sort(this.byDate.bind(this));
            })
          )
          .subscribe((deployments) => {
            this._deployments.next(deployments);
            resolve(deployments);
          });
      } else {
        resolve([]);
      }
    });
  }

  private byDate(a: TranslationDeployment, b: TranslationDeployment): number {
    return a.datetime > b.datetime ? -1 : 1;
  }
}
