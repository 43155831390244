import {
  appendVisibilitySettings,
  ChipRendererComponent,
  ChipRendererConfig,
  ColumnDefinition,
  ColumnRendererComponent,
  DateRendererComponent,
  DateRendererConfig,
  HeaderRendererComponent,
  TextFilterComponent,
  TranslationRendererComponent,
} from '@gea/digital-ui-lib';
import { CheckboxRendererComponent } from '../renderers/checkbox-renderer/checkbox-renderer.component';
import { CheckboxHeaderRendererComponent } from '../renderers/checkbox-header-renderer/checkbox-header-renderer.component';
import { OrgaData } from '@gea-id/shared';

const orgaPrefix = 'ORGANIZATIONS.LIST.';

export const organizationColumnConfig: ColumnDefinition[] = appendVisibilitySettings([
  {
    displayName: 'X.LABEL.SELECT',
    key: 'select',
    renderer: {
      component: CheckboxRendererComponent as ColumnRendererComponent<OrgaData>,
    },
    headerRenderer: {
      component: CheckboxHeaderRendererComponent as HeaderRendererComponent<OrgaData>,
    },
  },
  {
    displayName: 'X.LABEL.NAME',
    key: 'name',
    filter: {
      component: TextFilterComponent,
    },
    width: 500,
    minWidth: 250,
  },
  {
    displayName: 'X.LABEL.TYPE',
    key: 'type',
    renderer: {
      component: TranslationRendererComponent as ColumnRendererComponent<unknown>,
      config: {
        prefix: 'X.ORGA_TYPE.',
      },
    },
    width: 200,
    minWidth: 100,
  },
  {
    displayName: 'X.LABEL.COUNTRY',
    key: 'address.country',
    renderer: {
      component: TranslationRendererComponent as ColumnRendererComponent<unknown>,
      config: {
        prefix: 'COUNTRIES.',
      },
    },
    filter: {
      component: TextFilterComponent,
    },
    width: 300,
    minWidth: 150,
  },
  // Optional
  {
    displayName: 'X.LABEL.OWNER',
    key: 'owner',
    sortable: false,
    visible: false,
    width: 400,
    minWidth: 200,
  },
  {
    displayName: orgaPrefix + 'CUSTOMER_NUMBER',
    key: 'customerNumber',
    filter: {
      component: TextFilterComponent,
    },
    visible: false,
    width: 400,
    minWidth: 200,
  },
  {
    displayName: orgaPrefix + 'STATUS.HEADER',
    key: 'verificationStatus',
    sortable: true,
    renderer: {
      component: ChipRendererComponent as ColumnRendererComponent<unknown>,
      config: {
        mapping: {
          VERIFIED: {
            severity: 'success',
            text: orgaPrefix + 'STATUS.VERIFIED',
          },
          NOT_REQUESTED: {
            severity: 'default',
            text: orgaPrefix + 'STATUS.NOT_REQUESTED',
          },
          PENDING: {
            severity: 'information',
            text: orgaPrefix + 'STATUS.PENDING',
          },
        },
      } as ChipRendererConfig,
    },
    visible: false,
    width: 400,
    minWidth: 200,
  },
  createDateTimeColumn(orgaPrefix + 'VERIFICATION_REQUEST_DATE', 'verificationRequestTime'),
  {
    displayName: 'X.LABEL.CITY',
    key: 'address.city',
    filter: {
      component: TextFilterComponent,
    },
    visible: false,
    width: 400,
    minWidth: 200,
  },
  {
    displayName: 'X.LABEL.ZIP',
    key: 'address.zipCode',
    filter: {
      component: TextFilterComponent,
    },
    visible: false,
    width: 400,
    minWidth: 200,
  },
  {
    displayName: 'X.LABEL.STREET',
    key: 'address.street',
    filter: {
      component: TextFilterComponent,
    },
    visible: false,
    width: 400,
    minWidth: 200,
  },
  createDateTimeColumn(orgaPrefix + 'CREATED_AT', 'createdAt'),
  createDateTimeColumn('X.LABEL.LAST_MODIFIED_DATE', 'lastModifiedAt'),
]);

function createDateTimeColumn(displayName: string, key: string): ColumnDefinition {
  return {
    displayName,
    key,
    sortable: true,
    renderer: {
      component: DateRendererComponent as ColumnRendererComponent<unknown>,
      config: {
        format: 'dd.MM.yyyy HH:mm:ss',
      } as DateRendererConfig,
    },
    visible: false,
    width: 400,
    minWidth: 200,
  };
}
