import { ActivatedRouteSnapshot } from '@angular/router';

export const appRoutes = {
  BASIC_INFO: 'info',
  ASSETS:'assets'

};



export const appIdGuards = {

  isValid: (route: ActivatedRouteSnapshot) => {
    const appId = route.paramMap.get('appId');
    if (!appId) {
      return false;
    }
    return  /^\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/.test(appId);
  },
};
