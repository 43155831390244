import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  TextFilterComponent,
  FilterTableSettings,
  TableServiceV2,
  extractRequestParams,
  ErrorHandlerV2Service,
  TranslationRendererComponent,
  ApiErrorResponse,
  ColumnRendererComponent,
  appendVisibilitySettings,
} from '@gea/digital-ui-lib';
import { TranslateService } from '@ngx-translate/core';
import { AdministratorsApiService, AdministratorResponse } from '@gea-id/shared';

@Component({
  selector: 'gea-id-workspace-gea-user-list',
  templateUrl: './gea-user-list.component.html',
  styleUrl: './gea-user-list.component.scss',
})
export class GeaUserListComponent implements OnInit {
  columnDefinitions = appendVisibilitySettings([
    {
      displayName: 'X.LABEL.FIRST_NAME',
      key: 'firstName',
      filter: {
        component: TextFilterComponent,
      },
    },
    {
      displayName: 'X.LABEL.LAST_NAME',
      key: 'lastName',
      filter: {
        component: TextFilterComponent,
      },
    },
    {
      displayName: 'X.LABEL.EMAIL',
      key: 'email',
      width: 300,
      filter: {
        component: TextFilterComponent,
      },
    },
    {
      displayName: 'USERS.LIST.AAD_GROUP',
      key: 'aadGroup',
      renderer: {
        component: TranslationRendererComponent as ColumnRendererComponent<unknown>,
        config: {
          prefix: 'USERS.LIST.AAD_GROUP_NAME.',
        },
      },
    },
    {
      displayName: 'USERS.LIST.SCOPE',
      key: 'scopeValues',
    },
  ]);
  geaUsers: (Omit<AdministratorResponse, 'scopeValues'> & { scopeValues: string })[] = [];
  totalRecords = 0;
  loading = false;

  constructor(
    private adminService: AdministratorsApiService,
    private tableService: TableServiceV2,
    private destroyed: DestroyRef,
    private translateService: TranslateService,
    private errorHandler: ErrorHandlerV2Service
  ) {}

  ngOnInit() {
    this.tableService
      .getFilterTableSettings('gea-user-list')
      .pipe(takeUntilDestroyed(this.destroyed))
      .subscribe((filter: FilterTableSettings) => {
        this.fetchGeaUsers(filter);
      });
  }

  fetchGeaUsers(filter: FilterTableSettings) {
    this.loading = true;
    const mappings: { [key: string]: string } = {
      email: 'emailContains',
      firstName: 'firstNameContains',
      lastName: 'lastNameContains',
    };

    const requestParams = extractRequestParams(filter, mappings);

    this.adminService.administratorsList(requestParams).subscribe({
      next: (users) => {
        this.totalRecords = users.entryCount;
        this.geaUsers = users.pageEntries.map((user) => {
          return {
            ...user,
            scopeValues: user.scopeValues.join(', '),
          };
        });
        this.loading = false;
      },
      error: (error: ApiErrorResponse) => {
        this.errorHandler.handleError(error);
        this.loading = false;
      },
    });
  }
}
