import { Component, DestroyRef, Input } from '@angular/core';

import { ColumnDefinition, ColumnRendererComponent } from '@gea/digital-ui-lib';
import { Observable, of } from 'rxjs';

import { AppnameRendererComponent } from '../appname-renderer/appname-renderer.component';
import { DatetimeRendererComponent } from '../datetime-renderer/datetime-renderer.component';
import { TranslationDeployment, TranslationFilesApiService } from '../translation-files-api.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'gea-id-workspace-latest-deployments',
  templateUrl: './latest-deployments.component.html',
})
export class LatestDeploymentsComponent {
  @Input() loading = false;
  @Input() highlightRow = false;
  columns: ColumnDefinition[] = [
    {
      key: 'app',
      displayName: 'ADMINISTRATION_TASKS.COL_APP',
      renderer: { component: AppnameRendererComponent as ColumnRendererComponent<unknown> },
    },
    {
      key: 'datetime',
      displayName: 'ADMINISTRATION_TASKS.COL_DATETIME',
      renderer: { component: DatetimeRendererComponent as ColumnRendererComponent<unknown> },
    },
  ];
  data = [{ app: 'Euro', datetime: '01.01.2001 10:00 Uhr' }];
  deployments$: Observable<TranslationDeployment[]> = of([]);
  highlightedRow?: Date;
  constructor(
    private api: TranslationFilesApiService,
    private destroy$: DestroyRef
  ) {
    this.deployments$ = this.api.deployments$;
    this.deployments$.pipe(takeUntilDestroyed(this.destroy$)).subscribe((deployments) => {
      if (this.highlightRow) {
        this.highlightedRow = deployments[0]?.datetime;
      }
    });
  }
}
