import { map, Observable } from 'rxjs';
import { ApiService, FilterTableSettings, ListResponse } from '@gea/digital-ui-lib';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { IFeatureFlag } from '../models/feature-flag.model';
import { IFeatureFlagAssignment, NewFeatureFlagAssignment } from '../models/feature-flag-assignment.model';
import { CustomParamService } from './param.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagApiService {
  url = 'feature-flag-service/v1/feature-flags';
  apiVersion?: string;

  constructor(
    private api: ApiService,
    private paramService: CustomParamService
  ) {}

  getAllFeatureFlagAssignments(tableFilter: FilterTableSettings): Observable<ListResponse<IFeatureFlagAssignment[]>> {
    const params = this.paramService.getParams(tableFilter);
    return this.api
      .get<ListResponse<IFeatureFlagAssignment[]>>(`${this.url}/assignments`, { params }, this.apiVersion)
      .pipe(map(this.mapToFeatureFlagAssignmentDto));
  }

  setUrl(url?: string) {
    this.url = url ?? this.url;
    return this;
  }

  private mapToFeatureFlagAssignmentDto = (
    dto: ListResponse<IFeatureFlagAssignment[]>
  ): ListResponse<IFeatureFlagAssignment[]> => {
    if (!dto) {
      return { entryCount: 0, pageEntries: [] } as ListResponse<IFeatureFlagAssignment[]>;
    }
    dto.pageEntries?.map((featureFlagAssignment) => {
      if (featureFlagAssignment.featureFlag != null) {
        featureFlagAssignment.featureFlagDisplayName = featureFlagAssignment.featureFlag.displayName;
      }
      return featureFlagAssignment;
    });
    return dto;
  };

  update(id: string, featureFlagAssignment: NewFeatureFlagAssignment): Observable<IFeatureFlagAssignment> {
    return this.api.put<IFeatureFlagAssignment>(
      `${this.url}/assignments/${id}`,
      { ...featureFlagAssignment, id }, // FIXME id should only be necessary in path, not here
      {},
      this.apiVersion
    );
  }

  create(featureFlagAssignment: NewFeatureFlagAssignment): Observable<IFeatureFlagAssignment> {
    return this.api.post<IFeatureFlagAssignment>(`${this.url}/assignments`, featureFlagAssignment, {}, this.apiVersion);
  }

  delete(id: string): Observable<IFeatureFlagAssignment> {
    return this.api.delete<IFeatureFlagAssignment>(`${this.url}/assignments/${id}`, {}, this.apiVersion);
  }

  find(id: string) {
    return this.api.get<IFeatureFlagAssignment>(`${this.url}/assignments/${id}`);
  }

  getAllFeatureFlags(params: HttpParams): Observable<ListResponse<IFeatureFlag[]>> {
    return this.api.get<ListResponse<IFeatureFlag[]>>(`${this.url}`, { params }, this.apiVersion);
  }

  setApiVersion(apiVersion?: string) {
    this.apiVersion = apiVersion ?? this.apiVersion;
    return this;
  }
}
